<template>
  <Modal
      id="WorkEdit"
      width="60%"
      :open="visible"
      @cancel="visible = !visible"
      @ok="submit"
      :title="title"
  >
    <Row align="middle" justify="center">
      <Col span="18">
        <Row>
          <Col span="24">
            <Form
                ref="formRef"
                :model="form"
                layout="vertical"
                :rules="validateRules"
            >
              <Row justify="space-around">
<!--                <Col span="10">-->
<!--                  <FormItem :label="$t('workList.table.id_use')" name="idUse">-->
<!--                    &lt;!&ndash;关联表的下拉选择&ndash;&gt;-->
<!--                    <Select-->
<!--                        v-model:value="form.idUse"-->
<!--                        :placeholder="$t('workList.validate.required.id_use')"-->
<!--                        :dropdownStyle="{ zIndex: 9999999950 }"-->
<!--                        :disabled="actionType === 'edit'"-->
<!--                    >-->
<!--                      <SelectOption :key="sk" v-for="(item,sk) in userList" :value="item['idUse']">-->
<!--                        {{ item['account'] }}-->
<!--                      </SelectOption>-->
<!--                    </Select>-->
<!--                  </FormItem>-->
<!--                </Col>-->
                <Col span="10" v-if="actionType === 'edit'">
                  <FormItem :label="$t('workList.table.workNo')" name="workNo">
                    <Input :placeholder="$t('workList.validate.workNo')"
                           v-model:value="form.workNo" disabled>
                    </Input>
                  </FormItem>
                </Col>
                <Col span="10">
                  <FormItem :label="$t('workList.table.company')" name="company">
                    <Input :placeholder="$t('workList.validate.company')"
                           v-model:value="form.company">
                    </Input>
                  </FormItem>
                </Col>
                <Col span="10">
                  <FormItem :label="$t('workList.table.workType')" name="workType">
                    <Select
                        v-model:value="form.workType"
                        :placeholder="$t('workList.validate.workType')"
                        :dropdownStyle="{ zIndex: 9999999950 }"
                    >
                      <SelectOption value="1"> {{ $t('workList.workType.0') }} </SelectOption>
                      <SelectOption value="2"> {{ $t('workList.workType.1') }} </SelectOption>
                      <SelectOption value="3"> {{ $t('workList.workType.2') }} </SelectOption>
                      <SelectOption value="4"> {{ $t('workList.workType.3') }} </SelectOption>
                    </Select>
                  </FormItem>
                </Col>
                <Col span="10">
                  <FormItem :label="$t('workList.table.channel')" name="channel">
                    <Input :placeholder="$t('workList.validate.channel')"
                           v-model:value="form.channel">
                    </Input>
<!--                    <Select-->
<!--                        v-model:value="form.channel"-->
<!--                        :placeholder="$t('workList.validate.channel')"-->
<!--                        :dropdownStyle="{ zIndex: 9999999950 }"-->
<!--                    >-->
<!--                      <SelectOption value="1"> {{ $t('noticeManage.noticeName.0') }} </SelectOption>-->
<!--                      <SelectOption value="2"> {{ $t('noticeManage.noticeName.1') }} </SelectOption>-->
<!--                    </Select>-->
                  </FormItem>
                </Col>
                <Col span="10" v-if="actionType === 'edit'">
                  <FormItem :label="$t('workList.table.email')" name="email">
                    <Input :placeholder="$t('workList.validate.email')"
                           v-model:value="form.email">
                    </Input>
                  </FormItem>
                </Col>
                <Col span="10" v-if="actionType === 'edit'">
                  <FormItem :label="$t('workList.table.phone')" name="phone">
                    <Input :placeholder="$t('workList.validate.phone')"
                           v-model:value="form.phone">
                    </Input>
                  </FormItem>
                </Col>
                <Col span="10">
<!--                  <FormItem :label="$t('workList.table.ntfType')" name="ntfType">-->
<!--                    <Input :placeholder="$t('workList.validate.ntfType')"-->
<!--                           v-model:value="form.ntfType">-->
<!--                    </Input>-->
<!--                  </FormItem>-->
                  <FormItem :label="$t('workList.table.ntfType')" name="ntfType">
                    <Select
                        v-model:value="form.ntfType"
                        :placeholder="$t('workList.validate.ntfType')"
                        :dropdownStyle="{ zIndex: 9999999950 }"
                    >
                      <SelectOption value="1"> {{$t('workList.ntfType.0')}} </SelectOption>
                      <SelectOption value="2"> {{$t('workList.ntfType.1')}} </SelectOption>
                      <SelectOption value="3"> {{$t('workList.ntfType.2')}} </SelectOption>
                      <SelectOption value="4"> {{$t('workList.ntfType.3')}} </SelectOption>
                      <SelectOption value="5"> {{$t('workList.ntfType.4')}} </SelectOption>
                      <SelectOption value="6"> {{$t('workList.ntfType.5')}} </SelectOption>
                      <SelectOption value="7"> {{$t('workList.ntfType.6')}} </SelectOption>
                      <SelectOption value="8"> {{$t('workList.ntfType.7')}} </SelectOption>
                      <SelectOption value="9"> {{$t('workList.ntfType.8')}} </SelectOption>
                      <SelectOption value="10"> {{$t('workList.ntfType.9')}} </SelectOption>
                      <SelectOption value="11"> {{$t('workList.ntfType.10')}} </SelectOption>
                      <SelectOption value="12"> {{$t('workList.ntfType.11')}} </SelectOption>
                      <SelectOption value="13"> {{$t('workList.ntfType.12')}} </SelectOption>
                      <SelectOption value="14"> {{$t('workList.ntfType.13')}} </SelectOption>
                      <SelectOption value="15"> {{$t('workList.ntfType.14')}} </SelectOption>
                      <SelectOption value="16"> {{$t('workList.ntfType.15')}} </SelectOption>
                      <SelectOption value="17"> {{$t('workList.ntfType.16')}} </SelectOption>
                      <SelectOption value="18"> {{$t('workList.ntfType.17')}} </SelectOption>
                      <SelectOption value="19"> {{$t('workList.ntfType.18')}} </SelectOption>
                      <SelectOption value="20"> {{$t('workList.ntfType.19')}} </SelectOption>
                      <SelectOption value="21"> {{$t('workList.ntfType.20')}} </SelectOption>
                      <SelectOption value="22"> {{$t('workList.ntfType.21')}} </SelectOption>
                      <SelectOption value="23"> {{$t('workList.ntfType.22')}} </SelectOption>
                      <SelectOption value="24"> {{$t('workList.ntfType.23')}} </SelectOption>
                      <SelectOption value="25"> {{$t('workList.ntfType.24')}} </SelectOption>
                    </Select>
                  </FormItem>
                </Col>
                <Col span="10" v-if="actionType === 'edit'">
                  <FormItem :label="$t('workList.table.propertyName')" name="propertyName">
                    <Input :placeholder="$t('workList.validate.propertyName')"
                           v-model:value="form.propertyName">
                    </Input>
                  </FormItem>
                </Col>
                <Col span="10" v-if="actionType === 'edit'">
                  <FormItem :label="$t('workList.table.propertyNum')" name="propertyNum">
                    <Input :placeholder="$t('workList.validate.propertyNum')"
                           v-model:value="form.propertyNum">
                    </Input>
                  </FormItem>
                </Col>
                <Col span="10" v-if="actionType === 'edit'">
                  <FormItem :label="$t('workList.table.propertyFloor')" name="propertyFloor">
                    <Input :placeholder="$t('workList.validate.propertyFloor')"
                           v-model:value="form.propertyFloor">
                    </Input>
                  </FormItem>
                </Col>
                <Col span="10" v-if="actionType === 'edit'">
                  <FormItem :label="$t('workList.table.addr')" name="addr">
                    <Input :placeholder="$t('workList.validate.addr')"
                           v-model:value="form.addr">
                    </Input>
                  </FormItem>
                </Col>
                <Col span="10">
                  <FormItem :label="$t('workList.table.status')" name="status">
                    <Select v-model:value="form.status" :placeholder="$t('serviceManage.validate.status')" :dropdownStyle="{zIndex: 9999999950}">
                      <SelectOption value="1">{{ $t('workList.status.0') }}</SelectOption>
                      <SelectOption value="2">{{ $t('workList.status.1') }}</SelectOption>
                      <SelectOption value="3">{{ $t('workList.status.2') }}</SelectOption>
                      <SelectOption value="4">{{ $t('workList.status.3') }}</SelectOption>
                    </Select>
                  </FormItem>
                </Col>
                <Col span="10">
                  <FormItem :label="$t('car.field.id_use')" name="userId">
                    <!--关联表的下拉选择-->
                    <Select
                        v-model:value="form.userId"
                        :placeholder="$t('car.validate.required.id_use')"
                        :dropdownStyle="{ zIndex: 9999999950 }"
                        :disabled="actionType === 'edit'"
                    >
                      <SelectOption :key="sk" v-for="(item,sk) in userList" :value="item['idUse']">
                        {{ item['account'] }}
                      </SelectOption>
                    </Select>
                  </FormItem>
                </Col>
                <Col span="10">
<!--                  <FormItem :label="$t('workList.table.involved')" name="involved">-->
<!--                    <Select v-model:value="form.involved" :placeholder="$t('serviceManage.validate.type')" :dropdownStyle="{zIndex: 9999999950}">-->
<!--                      <SelectOption value="0">{{ $t('public.isTrue.0') }}</SelectOption>-->
<!--                      <SelectOption value="1">{{ $t('public.isTrue.1') }}</SelectOption>-->
<!--                    </Select>-->
<!--                  </FormItem>-->
                </Col>
                <Col span="22">
                  <FormItem :label="$t('workList.table.content')" name="content">
<!--                    <Input :placeholder="$t('workList.validate.content')"-->
<!--                           v-model:value="form.content">-->
<!--                    </Input>-->
                    <Textarea
                        v-model:value="form.content"
                        :placeholder="$t('workList.validate.content')"
                        :auto-size="{ minRows: 5, maxRows: 10 }"
                        showCount
                    />
                  </FormItem>
                </Col>
<!--                <Col span="10"></Col>-->
              </Row>
            </Form>
          </Col>
        </Row>
      </Col>
    </Row>
  </Modal>
</template>
<script>
export default {
  name: "WorkEdit",
}
</script>
<script setup>
import {
  Modal,
  Row,
  Col,
  Form,
  FormItem,
  Input,
  InputNumber,
  Textarea,
  Select,
  SelectOption,
  message,
} from "ant-design-vue";
import {WorkAdd, WorkEdit} from "@/api/work";
import {ref} from "vue";
import {useI18n} from "vue-i18n";
import {useStore} from "vuex";
import {UserListAll} from "@/api/user";

const store = useStore()
const i18n = useI18n()

const emits = defineEmits(['updateList'])
const formRef = ref()

const visible = ref(false)
let actionType = ref("")
let title = ref("")

const validateRules = {
  workNo: [
    {
      required: true,
      message: i18n.t("workList.validate.workNo"),
      trigger: "change",
    }
  ],
}
let userList = ref([])
let form = ref({})

function addShow(uList) {
  getUserList()
  title.value = i18n.t("public.name.add")
  actionType.value = "add";
  _resetData();
  userList.value = uList
  visible.value = true;
}

async function editShow(obj, uList) {
  getUserList()
  title.value = i18n.t("public.name.edit")
  _resetData();
  actionType.value = "edit";
  form.value = JSON.parse(JSON.stringify(obj));
  userList.value = uList
  visible.value = true;
}

function _resetData() {
  form.value = {
    workNo: "",
    company: "沙田303管理有限公司",
    workType: "1",
    channel: "APP",
    email: "",
    phone: "",
    ntfType: "2",
    obNo: "",
    propertyName: undefined,
    propertyNum: "",
    propertyFloor: "",
    addr: "",
    involved: undefined,
    content: "",
    status: "1",
    idUse: undefined
  };
}

function submit() {
  formRef.value?.validate().then(() => {
        let api;
        switch (actionType.value) {
          case "add":
            api = new WorkAdd();
            break;
          case "edit":
            api = new WorkEdit();
            break;
          default:
            return false;
        }
        api.post(form.value).then(response => {
          if (0 === parseInt(response.data.code)) {
            message.success(i18n.t("succeedMsg.0"));
            emits("updateList", response.data.data);
            visible.value = false;
          } else {
            if(response.data.code !== undefined){message.error(i18n.t("errorMsg." + response.data.code));}
          }
        });
      }).catch(() => {});
}

function getUserList() {
  const api = new UserListAll();
  api.get().then((response) => {
    if (0 === parseInt(response.data.code)) {
      userList.value = response.data.data;
    } else {
      if(response.data.code !== undefined){message.error(i18n.t("errorMsg." + response.data.code));}
    }
  });
}


defineExpose({
  addShow,
  editShow,
})
</script>

<style lang="less">
</style>
