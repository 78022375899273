<template>
  <Row id="PageContent">
    <!-- 加载状态-->
    <Spin size="large" :spinning="false"/>
    <Col :span="24" class="content">
      <!-- 查询表单-->
      <Row :gutter="[16,32]">
        <Col :span="24">
          <Form :model="searchForm">
            <Row :gutter="16">
              <Col :span="6">
                <FormItem :label="$t('noticeManage.field.toFrom')" toFrom="toFrom">
                  <Input :placeholder="$t('noticeManage.validate.toFrom')" v-model:value="searchForm.accountNumber" allowClear></Input>
                </FormItem>
              </Col>
<!--              <Col :span="6">-->
<!--                <FormItem :label="$t('noticeManage.field.toFrom')">-->
<!--                  <Input :placeholder="$t('noticeManage.validate.toFrom')" v-model:value="searchForm.accountCountry" allowClear></Input>-->
<!--                </FormItem>-->
<!--              </Col>-->
<!--              <Col :span="6">-->
<!--                <FormItem :label="$t('noticeManage.field.toFrom')">-->
<!--                  <Input :placeholder="$t('noticeManage.validate.toFrom')" v-model:value="searchForm.accountType" allowClear></Input>-->
<!--                </FormItem>-->
<!--              </Col>-->
            </Row>
          </Form>
          <Row style="margin-bottom: 5px">
            <Col :span="8">
<!--              <Button type="primary" size="default" @click="add()">{{ $t('public.name.add') }}</Button>-->
<!--              <span>&nbsp;</span>-->
<!--              <Button type="primary" size="default" danger @click="delByIds()" :disabled="selectedRowKeys === undefined || selectedRowKeys.length === 0">{{ $t('public.name.batchDel') }}</Button>-->
            </Col>
            <Col :span="8" :offset="8" style="text-align: right;">
              <Button type="primary" @click="search_submit">{{$t('public.name.search')}}</Button>
              <span>&nbsp;</span>
              <Button @click="search_reset">{{$t('public.name.reset')}}</Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <div>
          <Card :loading="loading" :title="list.identification" v-for="(item) in list" :key="item.identification">
            <template v-for="(it) in item.balance" :key="it.type">
              <CardGrid style="width: 25%">
                <Descriptions :title="$t('hsbc.' + it.type)" :column="1">
                  <DescriptionsItem :label="$t('hsbc.amount')">{{ it.amount?.amount + ' ' + it.amount?.currency }}</DescriptionsItem>
                  <DescriptionsItem :label="$t('hsbc.creditLine')">{{ it.creditLine[0].amount.amount + ' ' + it.creditLine[0].amount.currency }}</DescriptionsItem>
                  <DescriptionsItem :label="$t('hsbc.dateTime')">{{ it.dateTime.replace("T", " ") }}</DescriptionsItem>
<!--                  <DescriptionsItem label="dateTime">{{ moment(it.dateTime).format('YYYY-MM-DD HH:mm:ss') === 'Invalid date' ?  it.dateTime.replace("T", "") : moment( it.dateTime).format('YYYY-MM-DD HH:mm:ss') }}</DescriptionsItem>-->
<!--                  <DescriptionsItem label="creditDebitIndicator">{{ it.creditDebitIndicator }}</DescriptionsItem>-->
<!--                  <DescriptionsItem label="type">{{ it.type }}</DescriptionsItem>-->
<!--                  <DescriptionsItem label="included">{{ it.creditLine[0].included }}</DescriptionsItem>-->
                </Descriptions>
              </CardGrid>
            </template>
          </Card>
      </div>
    </Col>
  </Row>
</template>

<script>
export default {
  name: "NoticeManage"
}
</script>
<script setup>
import {createVNode, reactive, ref, toRefs} from "vue";
import {
  Button,
  Col,
  Form,
  FormItem,
  Input,
  message,
  Modal,
  Row,
  Select,
  SelectOption,
  Card,
  CardGrid,
  Spin,
  Descriptions,
  DescriptionsItem
} from "ant-design-vue";
import moment from "moment";
import {DownOutlined, ExclamationCircleOutlined, PlusOutlined} from "@ant-design/icons-vue";
import {NoticeGetListByPage, NoticeDelete} from "@/api/notice";
import FormModel from "./FormModel";
import {useStore} from "vuex";
import {useI18n} from "vue-i18n";
import {HsbcBalances} from "@/api/hsbc";

const store = useStore()
const i18n = useI18n()

let refFormModel = ref()

let value = ref()
let loading = ref(false)
let isClickLoading = ref(false)

const data = reactive({
  searchForm: {
    accountNumber: '112345600001',
    accountCountry: 'HK',
    accountType: 'CA',
  },
  list: [
    {
      identification: undefined,
      balance: [
        {
          type: undefined,
          amount: undefined,
          creditLine:[{
            amount: undefined
          }],
          dateTime: undefined
        }
      ]
    }
  ],
  selectedRowKeys: []
})
const {searchForm, list, selectedRowKeys} = toRefs(data)

function chang_page(pagination) {
  get_list();
}

function getTitle(typ) {
  if (typ === 'OPAV') {
    // return 'Opening Available  Balance'
    return '期初可用餘額'
  } else if (typ === 'OPBD') {
    // return 'Opening Booked Balance'
    return '期初帳面餘額'
  } else if (typ === 'ITAV') {
    // return 'Interim Available Balance'
    return '臨時可用餘額'
  } else if (typ === 'ITBD') {
    // return ' Interim Booked Balance'
    return '中期帳面餘額'
  }
  return typ
}

function get_list() {
  loading.value = true;
  const api = new HsbcBalances();
  // 构建查询参数
  const getData = {
    ...searchForm.value
  }
  api.get(getData).then((response) => {
    loading.value = false;
    if (0 === parseInt(response.data.code)) {
      const dataTmp = response.data.data;
      list.value = dataTmp.balances
    } else {
      list.value = [];
    }
  });
}

function add() {
  refFormModel.value?.addShow();
}

function edit(obj) {
  refFormModel.value?.editShow(obj);
}

function onSelectChange(ids) {
  selectedRowKeys.value = ids
}

function del(obj) {
  Modal.confirm({
    title: i18n.t("public.name.are_you_sure_delete") + "?",
    content: i18n.t("public.name.action_cannot_resumed"),
    icon: createVNode(ExclamationCircleOutlined),
    okText: i18n.t("public.name.yes"),
    okType: "danger",
    cancelText: i18n.t("public.name.no"),
    onOk: () => {
      const api = new NoticeDelete();
      let l = []
      l.push(obj.id)
      api.post({ids: l}).then((response) => {
        if (0 === response.data.code) {
          message.success(i18n.t("succeedMsg.0"))
        } else {
          message.error(i18n.t("errorMsg." + response.data.code))
        }
        // 刷新列表
        get_list();
      });
    },
  });
}

function delByIds() {
  Modal.confirm({
    title: i18n.t("public.name.are_you_sure_delete") + "?",
    content: i18n.t("public.name.action_cannot_resumed"),
    icon: createVNode(ExclamationCircleOutlined),
    okText: i18n.t("public.name.yes"),
    okType: "danger",
    cancelText: i18n.t("public.name.no"),
    onOk: () => {
      const api = new NoticeDelete();
      api.post({ids: selectedRowKeys.value}).then((response) => {
        if (0 === response.data.code) {
          message.success(i18n.t("succeedMsg.0"))
        } else {
          message.error(i18n.t("errorMsg." + response.data.code))
        }
        // 刷新列表
        get_list();
      });
    },
  });
}

function search_submit() {
  get_list()
}

function search_reset() {
  value.value = undefined
  searchForm.value = {}
  get_list()
}

function handleChange(v) {
  value.value = v
  let a = ''
  for (let i=0; v.length > i; i++) {
    a = a + v[i] + ','
  }
  if (a.endsWith(',')) {
    a = a.substring(0, a.length-1)
  }
  searchForm.value.noticeType = a
}

// 设置页标题
store.commit("setPageTitle", i18n.t('nav.name.HsbcManage'));
get_list();

defineExpose({
  get_list
})

</script>
