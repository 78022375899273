import {Base} from "@/api/Base"

const api = "hsbc"

export class HsbcBalances extends Base {
    path = api + "/balances"
}

export class HsbcTransactions extends Base {
    path = api + "/transactions"
}

export class HsbcInstantReceipt extends Base {
    path = api + "/instantReceipt"
}

export class HsbcPaymentsStatus extends Base {
    path = api + "/paymentsStatus"
}