<template>
  <Modal id="MeterDetail" width="50%" :open="visible" @cancel="visible = !visible" @ok="visible = !visible"
         :title="$t('meter.name.detailTitle')" :zIndex="9999999900">
    <Descriptions>
      <DescriptionsItem :label="$t('meter.field.id_met')">{{ data.idMet }}</DescriptionsItem>
      <DescriptionsItem :label="$t('meter.field.id_reg')">{{ data.regionName }}</DescriptionsItem>
      <DescriptionsItem :label="$t('meter.field.name')">{{ data.name }}</DescriptionsItem>
      <DescriptionsItem label="Current">{{ data.current }}</DescriptionsItem>
      <DescriptionsItem :label="$t('meter.field.current_threshold')">{{ data.currentThreshold }}</DescriptionsItem>
    </Descriptions>
  </Modal>
</template>
<script>
export default {
  name: "MeterDetail",
}
</script>
<script setup>
import {Modal, Descriptions, DescriptionsItem} from "ant-design-vue";
import {reactive, ref, toRefs} from "vue";

let visible = ref(false)
const dataTemp = reactive({
  data: {}
})
const {data} = toRefs(dataTemp)

async function detailShow(obj) {
  data.value = obj;
  visible.value = true;
}

defineExpose({
  detailShow
})
</script>