<template>
  <Layout id="Home" has-sider>
    <LayoutSider
        v-model:collapsed="collapsed"
        breakpoint="xs"
        class="home-sider"
    >
      <Nav ref="refNav" :collapsed="collapsed"></Nav>
    </LayoutSider>
    <Layout>
      <LayoutHeader class="home-header">
        <Row>
          <Col :span="6">
            <Row>
              <Col>
                <MenuUnfoldOutlined :style="{fontSize: '22px'}" v-if="collapsed"
                                    @click="() => (collapsed = !collapsed)"/>
                <MenuFoldOutlined :style="{fontSize: '22px'}" v-else @click="() => (collapsed = !collapsed)"/>
              </Col>
              <Col>
                <PageHeader
                    style="padding: 7px 10px"
                    :title="store.getters.getPageTitle"
                    sub-title=""
                />
              </Col>
            </Row>
          </Col>

          <Col :span="18">
            <Row style="float: right;">
              <Col>
                <Upload :file-list="fileList" :before-upload="beforeUpload">
                  <img style="width: 16px;height: 16px;" :src="store.getters.getLogo" alt="logo" v-if="store.getters.getLogo">
                  <img style="width: 16px;height: 16px;" src="@/assets/logo.png" alt="logo" v-if="!store.getters.getLogo">
                  <Divider type="vertical"/>
                </Upload>
              </Col>
              <Col v-if="isSuperAdmin">
                <SelectSuperTenant isEnableDefaultSelect="1" @onClick="(v)=>{
                  initTenantSelect(v)
                }"/>
                <Divider type="vertical"/>
              </Col>
              <Col v-if="isAdmin">
                <SelectTenant ref="refSelectTenant" isEnableDefaultSelect="1" @onClick="(v)=>{clickTenant(v)}"/>
                <Divider type="vertical"/>
              </Col>
              <Col>
                {{ language }}
                <LanguageButton/>
                <Divider type="vertical"/>
              </Col>
              <Col>
                <span>{{ userName }}</span>
                <Dropdown :trigger="['click']">
                  <Button type="text">
                    <setting-outlined :style="{fontSize: '22px'}"/>
                  </Button>
                  <template #overlay>
                    <Menu>
                      <!-- <MenuItem key="personal" @click="personal">
                        <UserOutlined />
                        <span>{{ $t("public.name.personal") }}</span>
                      </MenuItem> -->
                      <MenuItem key="reset_password" @click="reset_password">
                        <UserOutlined/>
                        <span>{{ $t("public.name.reset_password") }}</span>
                      </MenuItem>
                      <MenuItem key="logout" @click="logout">
                        <LogoutOutlined/>
                        <span>{{ $t("public.name.logout") }}</span>
                      </MenuItem>
                    </Menu>
                  </template>
                </Dropdown>
                <Divider type="vertical"/>
              </Col>
              <Col>
                <Badge :count="msgCount">
                  <Button type="text" size="small" @click="onShowMsg">
                    <mail-outlined :style="{fontSize: '22px'}"/>
                  </Button>
                </Badge>
              </Col>
            </Row>
          </Col>
        </Row>
      </LayoutHeader>
      <LayoutContent class="home-content">
        <router-view v-slot="{ Component }">
          <component ref="refRouterView" :is="Component"/>
        </router-view>
      </LayoutContent>
      <LayoutFooter class="home-footer">{{ store.getters.getCopyRight }}</LayoutFooter>
    </Layout>
  </Layout>
  <PersonalEdit ref="refPersonalEdit"/>
  <PersonalPassword ref="refPasswordEdit"/>
  <MsgDrawer ref="refMsgDrawer" @onMsgCount="onMsgCount"/>

</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Home"
}
</script>
<script setup>
import {
  Layout,
  LayoutHeader,
  LayoutContent,
  LayoutSider,
  LayoutFooter,
  Row,
  Col,
  Dropdown,
  Button,
  Menu,
  message,
  Divider,
  MenuItem,
  Badge,
  PageHeader,
  Upload
} from "ant-design-vue";
import {
  LogoutOutlined,
  UserOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  SettingOutlined,
  MailOutlined,
  ControlOutlined
} from "@ant-design/icons-vue";
import Nav from "@/components/Nav.vue";
import PersonalEdit from "@/components/Home/PersonalEdit.vue";
import PersonalPassword from "@/components/Home/PersonalPassword.vue";
import LanguageButton from "@/components/Common/LanguageButton";
import MsgDrawer from "@/components/MsgDrawer";
import SelectSuperTenant from "@/components/SelectSuperTenant"
import SelectTenant from "@/components/SelectTenant"
import {Logout} from "@/api/employee";
import {useStore} from "vuex";
import {useRouter} from "vue-router/dist/vue-router"
import {ref, getCurrentInstance, onMounted} from "vue"
import {useI18n} from 'vue-i18n'
import {SettingsGetIconStr, SettingsUploadIconStr} from "@/api/settings";

const i18n = useI18n()
const store = useStore()
const router = useRouter()
const {ctx} = getCurrentInstance()

const refNav = ref()
const refRouterView = ref()
const refSelectTenant = ref()

let collapsed = ref(false)
const language = ref(store.getters.getLanguage)
const userName = ref(store.getters.getUserMsg.username)
const isSuperAdmin = ref(store.getters.getUserMsg.userType === '1')
const isAdmin = store.getters.getIsAdmin

const refPersonalEdit = ref()
const refPasswordEdit = ref()
const refMsgDrawer = ref()
const msgCount = ref(0)

const fileList = ref([])

const logout = () => {
  const api = new Logout();
  api.get().then((response, any) => {
    if (0 === parseInt(response.data.code)) {
      message.success(i18n.t("succeedMsg.0"));
      router.push({
        path: "/Login"
      })
      store.dispatch("logout");
    } else {
      if (response.data.code !== undefined) {
        message.error(i18n.t("errorMsg." + response.data.code));
      }
      // this.getCaptcha();
    }
  });
}

const beforeUpload = (file) => {
  fileList.value = []
  // 转base64,并上传
  // 检查文件类型是否为.ico
  if (file.type === 'image/x-icon' || file.type === 'image/png') {
    // 创建 FileReader 对象
    const reader = new FileReader();
    // 监听 FileReader 加载完成事件
    reader.onload = function (event) {
      // 将图片转换为 base64 编码的字符串
      const base64String = event.target.result;
      // 更新后台logo
      let api = new SettingsUploadIconStr();
      api.post({iconStr: base64String}).then(response => {
        if (0 === parseInt(response.data.code)) {
          // 更新缓存
          store.commit("setLogo", base64String)
          // 刷新浏览器
          location.reload();
        } else {
          if (response.data.code !== undefined) {
            message.error(this.$t("errorMsg." + response.data.code));
          }
        }
      });
    };
    // 读取文件并触发加载完成事件
    reader.readAsDataURL(file);
  } else {
    message.info(i18n.t('errorMsg.500602'))
  }
  return false;
}

const personal = () => {
  refPersonalEdit.value.show();
}
const reset_password = () => {
  refPasswordEdit.value.show();
}
// check login statuc
const checkLogin = () => {
  // if (this.$store.getters.getToken == "") {
  //   this.$router.push({ name: "Login" });
  // } else {
  //   const api = new apiLogin();
  //   api.get().then((response:any) => {
  //     if (1 != parseInt(response.data.code)) {
  //       this.$store.commit("setToken", "");
  //       this.$store.commit("setUsername", "");
  //       this.$store.commit("setname", "");
  //       this.$store.commit("setPortrait", "");
  //       this.$store.commit("setLastIp", "");
  //       this.$store.commit("setLastTime", 0);
  //       this.$router.push({ name: "Login" });
  //     }
  //   });
  // }
}

function toggleCollapsed() {
  refNav.value?.toggleCollapsed()
}

function getCollapsed() {
  refNav.value?.getCollapsed()
}

function onShowMsg() {
  refMsgDrawer.value?.showDrawer()
}

function onMsgCount(v) {
  msgCount.value = v
}

function initTenantSelect(v) {
  store.commit("setTenantKey", 'admin')
  refSelectTenant.value?.initSelect()

  // 获取logo
  let api = new SettingsGetIconStr();
  api.get().then(response => {
    if (0 === parseInt(response.data.code)) {
      // 更新缓存
      store.commit("setLogo", response.data.data)
    } else {
      if (response.data.code !== undefined) {
        message.error(this.$t("errorMsg." + response.data.code));
      }
    }
  });

  refRouterView.value?.get_list(v)
}

function clickTenant(v) {
  refRouterView.value?.get_list(v)
}

checkLogin();
onMounted(() => {
  refSelectTenant.value?.initSelect()
  if (!isSuperAdmin.value) {
    refMsgDrawer.value?.getUnReadCount().then(res => {
      msgCount.value = res
    })
  }

  // 获取logo
  let api = new SettingsGetIconStr();
  api.get().then(response => {
    if (0 === parseInt(response.data.code)) {
      // 更新缓存
      store.commit("setLogo", response.data.data)
    } else {
      if (response.data.code !== undefined) {
        message.error(this.$t("errorMsg." + response.data.code));
      }
    }
  });
})
</script>

<style lang="less">
@import url("style.less");
</style>
