import {Axios, Base} from "@/api/Base"

const api = "chargingStation"

export class ChargingStationAdd extends Base {
    path = api + "/add"
}

export class ChargingStationDelete extends Base {
    path = api + "/delete"
}

export class ChargingStationList extends Base {
    path = api + "/list"
}

export class ChargingStationEdit extends Base {
    path = api + "/edit"
}

export class ChargingStationListAll extends Base {
    path = api + "/listAll"
}

export class ChargingStationGetConfiguration extends Base {
    path = api + "/getConfiguration"
}

export class ChargingStationDataTransfer extends Base {
    path = api + "/dataTransfer"
}

export class ChargingStationGetStatistic extends Base {
    path = api + "/getStatistic"
}

export class ChargingStationGetStatisticEnergyUsage extends Base {
    path = api + "/getStatisticEnergyUsage"
}

export class ChargingStationGetStatisticNewUser extends Base {
    path = api + "/getStatisticNewUser"
}

export class ChargingStationGetStatisticTotalEnergy extends Base {
    path = api + "/getStatisticTotalEnergy"
}

export class ChargingStationGetStatisticTotalRevenue extends Base {
    path = api + "/getStatisticTotalRevenue"
}

export class ChargingStationGetStatisticUtilizationRate extends Base {
    path = api + "/getStatisticUtilizationRate"
}

export class ChargingStationQuickAdd extends Base {
    path = api + "/quickAdd"
}

export class ChargingStationQuickEdit extends Base {
    path = api + "/quickEdit"
}

export class ChargingStationGetConfig extends Base {
    path = api + "/getConfig"
}

export class ChargingStationSetConfig extends Base {
    path = api + "/setConfig"
}

// 获取每根电桩的使用情况
export class ChargingStationStatisticByChargingStation extends Base {
    path = api + "/statisticByChargingStation"
}

// 获取每根电桩的使用情况 导出Execl
export class ChargingStationStatisticByChargingStationToExcel extends Base {
    path = api + "/statisticByChargingStationToExcel"
}

// 获取电桩下拉列表
export class ChargingStationGetSelectList extends Base {
    path = api + "/getSelectList"
}

// 更新电桩固件
export class ChargingStationUpdateFirmware extends Base {
    path = api + "/updateFirmware"
}

// 获取按统计的用电报告
export class ChargingStationExportEnergyUsage extends Base {
    path = api + "/exportEnergyUsage"
}

// 重启电桩
export class ChargingStationReset extends Base {
    path = api + "/reset"
}

// 获取ocpp电桩配置
export class ChargingStationGetOcppInfo extends Base {
    path = api + "/getPileInfo"
}

// 设置ocpp电桩配置
export class ChargingStationSetOcppInfo extends Base {
    path = api + "/setPileInfo"
}

// 设置电桩状态
export class ChargingStationSetStatus extends Base {
    path = api + "/setStatus"
}