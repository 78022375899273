<template>
  <Row id="PageContent">
    <Col :span="24" class="content">
      <Row :gutter="[16,32]">
        <Col :span="24">
          <Form :model="searchForm">
            <Row :gutter="16">
              <Col :span="6">
                <FormItem :label="$t('workList.table.workNo')" name="workNo">
                  <Input :placeholder="$t('workList.validate.workNo')"
                         v-model:value="searchForm.workNo"></Input>
                </FormItem>
              </Col>
              <Col :span="6">
                <FormItem :label="$t('workList.table.workType')" name="idUse">
                  <Select
                      v-model:value="searchForm.workType"
                      :placeholder="$t('workList.validate.workType')"
                      :dropdownStyle="{ zIndex: 9999999950 }"
                  >
                    <SelectOption value="1"> {{ $t('workList.workType.0') }}</SelectOption>
                    <SelectOption value="2"> {{ $t('workList.workType.1') }}</SelectOption>
                    <SelectOption value="3"> {{ $t('workList.workType.2') }}</SelectOption>
                    <SelectOption value="4"> {{ $t('workList.workType.3') }}</SelectOption>
                  </Select>
                </FormItem>
              </Col>
              <Col :span="6">
                <FormItem :label="$t('workList.table.ntfType')" name="ntfType">
                  <Select
                      v-model:value="searchForm.ntfType"
                      :placeholder="$t('workList.validate.ntfType')"
                      :dropdownStyle="{ zIndex: 9999999950 }"
                  >
                    <SelectOption value="1"> {{ $t('workList.ntfType.0') }}</SelectOption>
                    <SelectOption value="2"> {{ $t('workList.ntfType.1') }}</SelectOption>
                    <SelectOption value="3"> {{ $t('workList.ntfType.2') }}</SelectOption>
                    <SelectOption value="4"> {{ $t('workList.ntfType.3') }}</SelectOption>
                    <SelectOption value="5"> {{ $t('workList.ntfType.4') }}</SelectOption>
                    <SelectOption value="6"> {{ $t('workList.ntfType.5') }}</SelectOption>
                    <SelectOption value="7"> {{ $t('workList.ntfType.6') }}</SelectOption>
                    <SelectOption value="8"> {{ $t('workList.ntfType.7') }}</SelectOption>
                    <SelectOption value="9"> {{ $t('workList.ntfType.8') }}</SelectOption>
                    <SelectOption value="10"> {{ $t('workList.ntfType.9') }}</SelectOption>
                    <SelectOption value="11"> {{ $t('workList.ntfType.10') }}</SelectOption>
                    <SelectOption value="12"> {{ $t('workList.ntfType.11') }}</SelectOption>
                    <SelectOption value="13"> {{ $t('workList.ntfType.12') }}</SelectOption>
                    <SelectOption value="14"> {{ $t('workList.ntfType.13') }}</SelectOption>
                    <SelectOption value="15"> {{ $t('workList.ntfType.14') }}</SelectOption>
                    <SelectOption value="16"> {{ $t('workList.ntfType.15') }}</SelectOption>
                    <SelectOption value="17"> {{ $t('workList.ntfType.16') }}</SelectOption>
                    <SelectOption value="18"> {{ $t('workList.ntfType.17') }}</SelectOption>
                    <SelectOption value="19"> {{ $t('workList.ntfType.18') }}</SelectOption>
                    <SelectOption value="20"> {{ $t('workList.ntfType.19') }}</SelectOption>
                    <SelectOption value="21"> {{ $t('workList.ntfType.20') }}</SelectOption>
                    <SelectOption value="22"> {{ $t('workList.ntfType.21') }}</SelectOption>
                    <SelectOption value="23"> {{ $t('workList.ntfType.22') }}</SelectOption>
                    <SelectOption value="24"> {{ $t('workList.ntfType.23') }}</SelectOption>
                    <SelectOption value="25"> {{ $t('workList.ntfType.24') }}</SelectOption>
                  </Select>
                </FormItem>
              </Col>
              <Col :span="6">
                <FormItem :label="$t('workList.table.status')" name="status">
                  <Select
                      v-model:value="searchForm.status"
                      :placeholder="$t('workList.validate.status')"
                      :dropdownStyle="{ zIndex: 9999999950 }"
                  >
                    <SelectOption value="1"> {{ $t('workList.status.0') }}</SelectOption>
                    <SelectOption value="2"> {{ $t('workList.status.1') }}</SelectOption>
                    <SelectOption value="3"> {{ $t('workList.status.2') }}</SelectOption>
                    <SelectOption value="4"> {{ $t('workList.status.3') }}</SelectOption>
                  </Select>
                </FormItem>
              </Col>
            </Row>
            <Row style="margin-bottom: 5px">
              <Col :span="8">
                <Button type="primary" @click="exportToExcel">{{ $t('public.name.Export') }}</Button>
              </Col>
              <Col :span="8" :offset="8" style="text-align: right;">
                <Button type="primary" @click="search_submit">{{ $t('public.name.search') }}</Button>&nbsp;
                <Button @click="search_reset">{{ $t('public.name.reset') }}</Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
      <div>
        <Table :columns="columns" :dataSource="list" :scroll="{x: 1800}" rowKey="workId" :loading="loading"
               :pagination="tablePagination" @change="chang_page">
          <!-- 标题插槽-->
          <template #headerCell="{ column }">
            <template v-if="column.dataIndex === 'action'">
              <span>
                {{ $t("public.table.action") }}
                <Button v-if="checkPermission('WorkList.Add')" type="primary" size="small" shape="circle"
                        @click="add()">
                  <PlusOutlined/>
                </Button>
              </span>
            </template>
          </template>

          <!-- 内容插槽-->
          <template #bodyCell="{column, record}">
            <template v-if="column.dataIndex === 'action'">
              <div>
                <div class="pc-display">
                  <!--                  <Button type="default" size="small" @click="detail(record)">{{ $t("public.name.detail") }}</Button>-->
                  <span>&nbsp;</span>
                  <Button v-if="checkPermission('WorkList.Edit')" type="primary" size="small" @click="edit(record)">
                    {{ $t("public.name.edit") }}
                  </Button>
                  <span>&nbsp;</span>
                  <Button v-if="checkPermission('WorkList.Delete')" type="primary" danger size="small"
                          @click="del(record.workId)">{{ $t("public.name.del") }}
                  </Button>
                </div>
              </div>
            </template>
          </template>

        </Table>
      </div>
    </Col>
    <Detail ref="refDetail"></Detail>
    <Edit ref="refEdit" @updateList="get_list"></Edit>
  </Row>
</template>
<script>
export default {
  name: "WordManage",
}
</script>
<script setup>
import {PlusOutlined} from "@ant-design/icons-vue";
import {createVNode, reactive, ref, toRefs} from "vue";
import {
  Row,
  Col,
  Table,
  Button,
  Dropdown,
  Menu,
  Modal,
  Form,
  Input,
  FormItem,
  MenuItem,
  message,
  Select,
  SelectOption
} from "ant-design-vue";
import {DownOutlined, ExclamationCircleOutlined} from "@ant-design/icons-vue";
import {WorkList, WorkDelete, WorkExportToExcel} from "@/api/work";
import Detail from "./Detail";
import Edit from "./Edit";
import {useI18n} from "vue-i18n";
import {useStore} from "vuex";
import moment from "moment";

const store = useStore()
const i18n = useI18n()

const refEdit = ref()
const refDetail = ref()

let loading = ref(false)
let userList = ref([])

const booleanList = [{value: 0, label: i18n.t("public.isTrue.0")}, {value: 1, label: i18n.t("public.isTrue.1")}]
const columns = [
  {
    title: i18n.t("workList.table.workId"),
    width: 60,
    ellipsis: true,
    fixed: "left",
    dataIndex: "workId",
    key: "workId"
  },
  {title: i18n.t("workList.table.workNo"), width: 150, ellipsis: true, dataIndex: "workNo", key: "workNo"},
  {title: i18n.t("workList.table.company"), width: 180, ellipsis: true, dataIndex: "company", key: "company"},
  // {title: i18n.t("workList.table.workType"), width: 80, ellipsis: true, dataIndex: "workType", key: "workType"},
  {
    title: i18n.t("workList.table.workType"),
    width: 120,
    ellipsis: true,
    dataIndex: "workType",
    key: "workType",
    customRender: ({text}) => {
      return i18n.t('workList.workType.' + (Number(text) - 1))
    }
  },
  {
    title: i18n.t("workList.table.channel"),
    width: 80,
    ellipsis: true,
    dataIndex: "channel",
    key: "channel",
    // customRender: ({text}) => {
    //   return i18n.t('noticeManage.noticeName.' + (Number(text) - 1))
    // }
  },
  {title: i18n.t("workList.table.email"), width: 180, ellipsis: true, dataIndex: "email", key: "email"},
  {title: i18n.t("workList.table.phone"), width: 180, ellipsis: true, dataIndex: "phone", key: "phone"},
  {
    title: i18n.t("workList.table.ntfType"),
    width: 180,
    ellipsis: true,
    dataIndex: "ntfType",
    key: "ntfType",
    customRender: ({text}) => {
      return i18n.t('workList.ntfType.' + (Number(text) - 1))
    }
  },
  // { title: i18n.t("workList.table.obNo"), width: 80, ellipsis: true, dataIndex: "obNo", key: "obNo" },
  {
    title: i18n.t("workList.table.propertyName"),
    width: 180,
    ellipsis: true,
    dataIndex: "propertyName",
    key: "propertyName"
  },
  {
    title: i18n.t("workList.table.propertyNum"),
    width: 80,
    ellipsis: true,
    dataIndex: "propertyNum",
    key: "propertyNum"
  },
  {
    title: i18n.t("workList.table.propertyFloor"),
    width: 80,
    ellipsis: true,
    dataIndex: "propertyFloor",
    key: "propertyFloor"
  },
  // {title: i18n.t("workList.table.addr"), width: 80, ellipsis: true, dataIndex: "addr", key: "addr"},
  {
    title: i18n.t("workList.table.involved"),
    width: 80,
    ellipsis: true,
    dataIndex: "involved",
    key: "involved",
    // customRender: ({text}) => {
    //   return i18n.t('public.isTrue.' + text)
    // }
  },
  {
    title: i18n.t("public.table.created_at"),
    width: 180,
    ellipsis: true,
    // fixed: "right",
    dataIndex: "createTime",
    key: "createTime",
    customRender: ({text}) => {
      return moment(text).format('YYYY-MM-DD HH:mm:ss')
    }
  },
  // {title: i18n.t("workList.table.tenantKey"), width: 80, ellipsis: true, dataIndex: "tenantKey", key: "tenantKey"},
  {title: i18n.t("public.name.createBy"), width: 150, ellipsis: true, dataIndex: "createBy", key: "createBy"},
  {
    title: i18n.t("workList.table.status"),
    width: 80,
    ellipsis: true,
    dataIndex: "status",
    key: "status",
    customRender: ({text}) => {
      return i18n.t('workList.status.' + (Number(text) - 1))
    }
  },
  {key: "action", width: 180, ellipsis: true, fixed: "right", dataIndex: "action"},
]

const data = reactive({
  list: [],
  searchForm: {},
  tablePagination: {
    pageSizeOptions: ["10", "20", "50", "100"],
    showSizeChanger: true,
    current: 1,
    pageSize: 10,
    total: 0,
  },
})
const {searchForm, tablePagination, list} = toRefs(data)

function chang_page(pagination) {
  tablePagination.value = pagination;
  get_list();
}

function get_list() {
  loading.value = true;
  const api = new WorkList();
  const getData = {
    page: tablePagination.value.current,
    limit: tablePagination.value.pageSize,
    ...searchForm.value
  }
  api.get(getData).then((response) => {
    if (0 === parseInt(response.data.code)) {
      const dataTmp = response.data.data;

      console.log("data => ", dataTmp)

      tablePagination.value.current = parseInt(dataTmp.current);
      tablePagination.value.pageSize = parseInt(dataTmp.pageSize);
      tablePagination.value.total = parseInt(dataTmp.total);
      list.value = dataTmp.data;
    } else {
      list.value = [];
    }
  }).finally(() => {
    loading.value = false;
  });
}

function add() {
  refEdit.value?.addShow(userList.value);
}

function detail(obj) {
  refDetail.value?.detailShow(obj);
}

function edit(obj) {
  refEdit.value?.editShow(obj, userList.value);
}

function del(id) {
  Modal.confirm({
    title: i18n.t("public.name.are_you_sure_delete") + "?",
    content: i18n.t("public.name.action_cannot_resumed"),
    icon: createVNode(ExclamationCircleOutlined),
    okText: i18n.t("public.name.yes"),
    okType: "danger",
    cancelText: i18n.t("public.name.no"),
    onOk: () => {
      const api = new WorkDelete();
      api.post({id: id}).then((response) => {
        if (0 === parseInt(response.data.code)) {
          get_list();
          message.success(i18n.t("succeedMsg.0"));
        } else {
          if (response.data.code !== undefined) {
            message.error(i18n.t("errorMsg." + response.data.code));
          }
        }
      });
    },
  });
}

function search_submit() {
  tablePagination.value.current = 1
  get_list()
}

function search_reset() {
  tablePagination.value.current = 1
  tablePagination.value.pageSize = 10
  searchForm.value = {}
  get_list()
}

function exportToExcel() {
  loading.value = true
  const api = new WorkExportToExcel()
  api.getDownLoad(searchForm.value).then(() => {
  }).finally(() => {
    loading.value = false
  })
}

// 设置页标题
store.commit("setPageTitle", i18n.t('nav.name.WorkList'));
get_list();

defineExpose({
  get_list
})
</script>

<style lang="less">
@import url("./style.less");
</style>
