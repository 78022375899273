<template>
  <Row id="PageContent">
    <Col :span="24" class="content">
      <Row :gutter="[16,32]">
        <Col :span="24" >
          <Form :model="searchForm">
            <Row :gutter="16">
              <Col :span="6">
                <FormItem :label="$t('meter.table.id_reg')" name="idReg">
                  <Select
                      v-model:value="searchForm.idReg"
                      show-search
                      :placeholder="$t('public.pleaseSelect')"
                      style="width: 100%"
                      :options="options"
                      :filter-option="filterOption"
                      @change="handleChange"
                      allowClear
                  ></Select>
                </FormItem>
              </Col>
              <Col :span="6">
                <FormItem :label="$t('chargingStation.table.id_met')" name="idMet">
                  <Select
                      v-model:value="searchForm.idMet"
                      show-search
                      :placeholder="$t('public.pleaseSelect')"
                      style="width: 100%"
                      :options="metOptions"
                      :filter-option="filterOption"
                      allowClear
                  ></Select>
                </FormItem>
              </Col>
              <Col :span="6">
                <FormItem :label="$t('chargingStation.table.enable')" name="enable">
                  <Select v-model:value="searchForm.enable" :placeholder="$t('public.pleaseSelect')" :dropdownStyle="{zIndex: 9999999950}" allowClear>
                    <SelectOption :value="0">{{ $t('public.isTrue.0') }}</SelectOption>
                    <SelectOption :value="1">{{ $t('public.isTrue.1') }}</SelectOption>
                  </Select>
                </FormItem>
              </Col>
              <Col :span="6">
                <FormItem :label="$t('chargingStation.table.charge_box_id')" name="chargeBoxId">
                  <Input :placeholder="$t('chargingStation.table.charge_box_id')" v-model:value="searchForm.chargeBoxId"></Input>
                </FormItem>
              </Col>

            </Row>
            <Row :gutter="16">
              <Col :span="6">
                <FormItem :label="$t('chargingStation.table.name')" name="name">
                  <Input :placeholder="$t('chargingStation.validate.name')" v-model:value="searchForm.name"></Input>
                </FormItem>
              </Col>
              <Col :span="6">
                <FormItem :label="$t('chargingStation.table.charger_type')" name="chargerType">
                  <Select
                      v-model:value="searchForm.chargerType"
                      :placeholder="$t('chargingStation.validate.charger_type')"
                      :options="chargerTypeList"
                      :dropdownStyle="{ zIndex: 9999999950 }"
                      allowClear
                  >
                  </Select>
                </FormItem>
              </Col>
              <Col :span="6">
                <FormItem :label="$t('region.table.postpaid')" name="postpaid">
                  <Select v-model:value="searchForm.postpaid" :placeholder="$t('public.pleaseSelect')" :dropdownStyle="{zIndex: 9999999950}" allowClear>
                    <SelectOption :value="0">{{ $t('public.isTrue.0') }}</SelectOption>
                    <SelectOption :value="1">{{ $t('public.isTrue.1') }}</SelectOption>
                    <SelectOption :value="2">{{ $t('order.table.ref_id_pla') }}</SelectOption>
                  </Select>
                </FormItem>
              </Col>
              <Col :span="6">
                <FormItem :label="$t('region.table.billingMethod')" name="billingMethod">
                  <Select v-model:value="searchForm.billingMethod" :placeholder="$t('region.validate.billingMethod')" :dropdownStyle="{zIndex: 9999999950}">
                    <SelectOption :value="0">{{ $t('chargingStation.billingMethodType.0') }}</SelectOption>
                    <SelectOption :value="1">{{ $t('chargingStation.billingMethodType.1') }}</SelectOption>
                    <SelectOption :value="2">{{ $t('chargingStation.billingMethodType.2') }}</SelectOption>
                    <SelectOption :value="3">{{ $t('chargingStation.billingMethodType.3') }}</SelectOption>
                    <SelectOption :value="4">{{ $t('chargingStation.billingMethodType.4') }}</SelectOption>
                  </Select>
                </FormItem>
              </Col>
            </Row>
          </Form>
          <Row style="margin-bottom: 5px">
            <Col :span="8">
              <!-- <Button v-if="checkPermission('ChargingStationManage.Add')" type="primary" @click="quickAdd()">
                  {{$t("chargingStation.name.quickAdd")}}
                </Button> -->
            </Col>
            <Col :span="8" :offset="8" style="text-align: right;">
              <Button type="primary" @click="search_submit">{{ $t('public.name.search') }}</Button>
              <span>&nbsp;</span>
              <Button @click="search_reset">{{ $t('public.name.reset') }}</Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <div>
        <Table :columns="columns" :dataSource="list" :scroll="{x: 1500}" rowKey="idChaPoi" :loading="loading" :pagination="tablePagination" @change="chang_page">

          <!-- 标题插槽-->
          <template #headerCell="{ column }">
            <template v-if="column.dataIndex === 'action'">
              <span>
                {{$t("public.table.action")}}
                <!--                <Button v-if="checkPermission('ChargingStationManage.Add')" type="primary" size="small" shape="circle" @click="add()">-->
                <!--                  <PlusOutlined />-->
                <!--                </Button>-->
              </span>
            </template>
          </template>

          <!-- 内容插槽-->
          <template #bodyCell="{column, record}">
            <template v-if="column.dataIndex === 'vQrcode'">
              <vue-qrcode :value="record.chargeBoxId" :options="{ width: 80 }"></vue-qrcode>
            </template>

            <template v-if="column.dataIndex === 'isEnableSwitch'">
              <Switch :checked="record.cStatus === 1" @click="e => switchClick(e, record.idChaPoi)" checked-children="ON" un-checked-children="OFF" />
            </template>

<!--            {value: 1, label: i18n.t('public.chargerType.0')},-->
<!--            {value: 2, label: i18n.t('public.chargerType.1')}-->
            <template v-if="column.dataIndex === 'enable'">
              <Tag v-if="record.enable === 1" color="green">{{ $t("public.isTrue.1") }}</Tag>
              <Tag v-else color="orange">{{ $t("public.isTrue.0") }}</Tag>
            </template>

            <template v-if="column.dataIndex === 'chargerType'">
              <Tag v-if="record.chargerType === 1" color="green">{{ $t("public.chargerType.0") }}</Tag>
              <Tag v-else-if="record.chargerType === 2" color="cyan">{{ $t("public.chargerType.1") }}</Tag>
              <Tag v-else color="orange">{{record.chargerType}}</Tag>
            </template>

            <template v-if="column.dataIndex === 'action'">
              <div class="pc-display">
                <Dropdown :trigger="['click']" @click.prevent>
                  <a>
                    {{ $t("public.name.setting") }}
                    <DownOutlined/>
                  </a>
                  <template #overlay>
                    <Menu>
                      <MenuItem v-if="checkPermission('ChargingStationManage.Edit')">
                        <Button type="primary" size="small" @click="edit(record)">{{$t("public.name.edit")}}</Button>
                      </MenuItem>
                      <MenuItem v-if="checkPermission('ChargingStationManage.Delete')">
                        <Button type="primary" danger size="small" @click="del(record.idChaPoi)">{{$t("public.name.del")}}</Button>
                      </MenuItem>
                      <MenuItem v-if="checkPermission('ChargingStationManage.Edit')">
                        <Button type="primary" size="small" @click.stop="showSetting(record)">{{$t("public.name.setting")}}</Button>
                      </MenuItem>
                      <MenuItem v-if="checkPermission('ChargingStationManage.Edit')">
                        <Button type="primary" size="small" @click.stop="updateFirmwareShow(record)">{{ $t("chargingStation.table.action.updateFirmware") }}</Button>
                      </MenuItem>
                      <MenuItem v-if="checkPermission('ChargingStationManage.Edit')">
                        <Button type="primary" size="small" @click.stop="reset(record, 'Hard')">{{ $t("chargingStation.table.action.hardReset") }}</Button>
                      </MenuItem>
                      <MenuItem v-if="checkPermission('ChargingStationManage.Edit')">
                        <Button type="primary" size="small" @click.stop="reset(record, 'Soft')">{{ $t("chargingStation.table.action.softReset") }}</Button>
                      </MenuItem>
                      <MenuItem v-if="checkPermission('ChargingStationManage.Edit')">
                        <Button type="primary" size="small" @click.stop="showOcppPileInfo(record.chargeBoxId)">OCPP</Button>
                      </MenuItem>
                    </Menu>
                  </template>
                </Dropdown>
              </div>
            </template>
          </template>
        </Table>
      </div>
    </Col>
    <ChargingStationDetail ref="refChargingStationDetail"></ChargingStationDetail>
    <ChargingStationQuickEdit ref="refChargingStationQuickEdit" @updateList="get_list"></ChargingStationQuickEdit>
    <SettingList ref="refSettingList" @updateList="get_list"></SettingList>
    <UpdateFirmwareModel ref="refUpdateFirmwareModel"/>
    <OcppConfig ref="refOcppConfig"/>
  </Row>
</template>
<script>
export default {
  name: "ChargingStationManage",
}
</script>
<script setup>
import {createVNode, reactive, ref, toRefs} from "vue";
import { Row, Col, Table, Button, Dropdown, Menu, MenuItem, Modal, Form, FormItem, Input, Select, SelectOption, message, Switch, Tag} from "ant-design-vue";
import { DownOutlined, ExclamationCircleOutlined, PlusOutlined } from "@ant-design/icons-vue";
import {
  ChargingStationList,
  ChargingStationDelete,
  ChargingStationReset,
  ChargingStationSetStatus
} from "@/api/chargingStation";
import ChargingStationDetail from "./ChargingStationDetail";
import ChargingStationQuickEdit from "@/components/Home/ChargingStationQuickEdit";
import OcppConfig from "./OcppConfig";
import moment from "moment";
import {GetUrlList} from "@/api/common";
import SettingList from "./SettingList";
import {RegionGetSelectList} from "@/api/region";
import {MeterGetSelectListByidReg} from "@/api/meter";
import UpdateFirmwareModel from "./UpdateFirmwareModel";
import {useStore} from "vuex";
import {useI18n} from "vue-i18n";
import {useRoute} from "vue-router/dist/vue-router";
import {superTenantEditInfo} from "@/api/superTenant";

const store = useStore()
const i18n = useI18n()
const route = useRoute()

const refChargingStationDetail = ref()
const refChargingStationQuickEdit = ref()
const refUpdateFirmwareModel = ref()
const refSettingList = ref()
const refOcppConfig = ref()

let loading = ref(false)

const chargerTypeList = [
  {value: 1, label: i18n.t('public.chargerType.0')},
  {value: 2, label: i18n.t('public.chargerType.1')}
]
const booleanList = [{value: 0,label: i18n.t('public.isTrue.0')},{value: 1,label: i18n.t('public.isTrue.1')}]
const columns = [
  {
    title: i18n.t("public.name.index"),
    ellipsis: true,
    width: 80,
    fixed: "left",
    customRender: ({text, record, index, column}) => `${(tablePagination.value.current-1) * tablePagination.value.pageSize +  index + 1}`
  },
  { title: i18n.t("public.name.qrcode"), key: "qrCode", width: 120, dataIndex: "vQrcode" },
  { title: i18n.t("superTenantManage.table.isEnable"), key: "cStatus", fixed: "center", width: 100, dataIndex: "isEnableSwitch" },
  { title: i18n.t("Space"), width: 180, ellipsis: true, dataIndex: "spaceName", key: "spaceName" },
  // { title: i18n.t("chargingStation.table.id_cha_poi"), width: 80, ellipsis: true,  dataIndex: "idChaPoi", key: "idChaPoi" },
  { title: i18n.t("meter.table.id_reg"), width: 180, ellipsis: true, dataIndex: "regionName", key: "regionName" },
  { title: i18n.t("chargingStation.table.id_met"), width: 180, ellipsis: true, dataIndex: "meterName", key: "meterName" },
  { title: i18n.t("chargingStation.table.charge_box_id"), width: 180, ellipsis: true, dataIndex: "chargeBoxId", key: "chargeBoxId" },
  { title: i18n.t("chargingStation.table.firmwareVersion"), width: 160, ellipsis: true, dataIndex: "firmwareVersion", key: "firmwareVersion" },
  { title: i18n.t("chargingStation.table.enable"), width: 100, ellipsis: true, dataIndex: "enable", key: "enable"},
  { title: i18n.t("chargingStation.table.last_heartbeat_time"), width: 180, ellipsis: true, dataIndex: "lastHeartbeatTime", key: "lastHeartbeatTime", customRender: ({text}) =>{return moment(text).format('YYYY-MM-DD HH:mm:ss')}},
  { title: i18n.t("chargingStation.table.charger_type"), width: 180, ellipsis: true, dataIndex: "chargerType", key: "chargerType", customRender: ({text}) =>{
      if(text)
        return chargerTypeList.find(item=>item.value===text).label
    }
  },
  { title: i18n.t("chargingStation.table.name"), width: 180, ellipsis: true, dataIndex: "name", key: "name" },
  { title: i18n.t("chargingStation.table.tenant_key"), width: 120, ellipsis: true, dataIndex: "tenantKey", key: "tenantKey" },
  { title: i18n.t("public.name.createTime"), width: 180, ellipsis: true, dataIndex: "createTime", key: "createTime" },
  {title: i18n.t("authority.table.sort"), width: 60, ellipsis: true, dataIndex: "sort", key: "sort"},
  { key: "action", width: 90, fixed: "right", dataIndex: "action" },
]

const data = reactive({
  // 区域搜索框
  options:[],
  // 电表搜索框
  metOptions: [],
  searchForm: {},
  list: [],
  tablePagination: {
    pageSizeOptions: ["10", "20", "50", "100"],
    showSizeChanger: true,
    current: 1,
    pageSize: 10,
    total: 0,
  },
})
const {options, metOptions, searchForm, list, tablePagination} = toRefs(data)

function chang_page(pagination) {
  tablePagination.value = pagination;
  get_list();
}

function get_list() {
  loading.value = true;
  // 获取电表列表
  getMeterList()
  // 获取区域列表
  getRegionSelectList()

  if (route.query.idReg) {
    searchForm.value.idReg = route.query.idReg
  }

  const api = new ChargingStationList();
  const getData = {
    ...searchForm.value,
    page: tablePagination.value.current,
    limit: tablePagination.value.pageSize
  }
  api.get(getData).then((response) => {
    loading.value = false;
    if (0 === parseInt(response.data.code)) {
      const dataTmp = response.data.data;
      tablePagination.value.current = parseInt(dataTmp.current);
      tablePagination.value.pageSize = parseInt(dataTmp.pageSize);
      tablePagination.value.total = parseInt(dataTmp.total);
      list.value = dataTmp.data;
    } else {
      list.value = [];
    }
  });
}

function detail(obj) {
  refChargingStationDetail.value?.detailShow(obj);
}

function add() {
}

function updateFirmwareShow(obj) {
  if (obj.enable !== 1) {
    message.error(i18n.t("errorMsg.500301"));
    return
  }
  refUpdateFirmwareModel.value?.onShow(obj);
}

// 重启电桩
function reset(obj, tp) {
  Modal.confirm({
    title: i18n.t("public.name.are_you_sure_operate") + "?",
    content: i18n.t("public.name.operate_remind"),
    icon: createVNode(ExclamationCircleOutlined),
    okText: i18n.t("public.name.yes"),
    okType: "danger",
    cancelText: i18n.t("public.name.no"),
    onOk: () => {
      // 强制重启电桩
      const api = new ChargingStationReset()
      api.get({idChaPoi: obj.idChaPoi, type: tp}).then((response) => {
        if (0 === parseInt(response.data.code)) {
          message.success(i18n.t("succeedMsg.0"));
        } else {
          if(response.data.code !== undefined){message.error(i18n.t("errorMsg." + response.data.code));}
        }
      });
    },
  });
}

// 获取COPP电桩配置信息
function showOcppPileInfo(chargeBoxId) {
  refOcppConfig.value?.editShow(chargeBoxId)
}

// 展示配置信息
function showSetting(obj) {
  if (obj.enable !== 1) {
    message.error(i18n.t("errorMsg.500301"));
    return
  }
  if (obj.idChaPoi !== undefined) {
    refSettingList.value.showList(obj.idChaPoi);
  } else {
    message.error(i18n.t("errorMsg.500102"));
  }
}

// 获取文件列表
function getImgUrl(api, url){
  return new Promise( (resolve, reject) =>{
    api.post({objNameList: url}).then(res => {
      resolve(res.data.data)
    })
  });
}

async function getAllImgList(obj) {
  const api = new GetUrlList();
  let imgList
  let l = []
  if (obj.chargeStationImagesList !== null) {
    for (let i = 0; i < obj.chargeStationImagesList.length; i++) {
      l.push(obj.chargeStationImagesList[i].url)
    }
    imgList = await getImgUrl(api, l)
  }
  return imgList
}

async function edit(obj) {
  loading.value = true
  // 设置图片展示列表
  obj.imgList = await getAllImgList(obj)
  delete obj.createTime
  delete obj.updateTime
  refChargingStationQuickEdit.value.editShow(obj);
  loading.value = false
}

function quickAdd(){
  refChargingStationQuickEdit.value.addShow();
}

function del(idChaPoi) {
  Modal.confirm({
    title: i18n.t("public.name.are_you_sure_delete") + "?",
    content: i18n.t("public.name.action_cannot_resumed"),
    icon: createVNode(ExclamationCircleOutlined),
    okText: i18n.t("public.name.yes"),
    okType: "danger",
    cancelText: i18n.t("public.name.no"),
    onOk: () => {
      const api = new ChargingStationDelete();
      api.post({ idChaPoi: idChaPoi }).then((response) => {
        if (0 === parseInt(response.data.code)) {
          get_list();
          message.success(i18n.t("succeedMsg.0"));
        } else {
          if(response.data.code !== undefined){message.error(i18n.t("errorMsg." + response.data.code));}
        }
      });
    },
  });
}

function search_submit() {
  tablePagination.value.current = 1
  get_list()
}

// 搜索框过滤筛选
function filterOption(input, option) {
  return option.label.indexOf(input) >= 0;
}

// 获取所有区域 id-name 列表
function getRegionSelectList() {
  const api = new RegionGetSelectList
  api.get().then(res => {
    if (0 === parseInt(res.data.code)) {
      options.value = res.data.data
    }
  })
}

function handleChange() {
  searchForm.value.idMet = undefined
  getMeterList()
}

function getMeterList() {
  const api = new MeterGetSelectListByidReg();
  api.get({idReg: searchForm.value.idReg}).then((response) => {
    if (0 === parseInt(response.data.code)) {
      metOptions.value = response.data.data;
    } else {
      if(response.data.code !== undefined){message.error(i18n.t("errorMsg." + response.data.code));}
    }
  });
}

function switchClick(e, id) {
  Modal.confirm({
    title: i18n.t("public.name.are_you_sure_operate") + "?",
    content: i18n.t("public.name.operate_remind") + "！",
    icon: createVNode(ExclamationCircleOutlined),
    okText: i18n.t("public.name.yes"),
    okType: "danger",
    cancelText: i18n.t("public.name.no"),
    onOk: () => {
      loading.value = true
      // 更新数据库及刷新列表
      const api = new ChargingStationSetStatus()
      api.post({id: id, isEnable: e}).then(response=>{
        if (0 === parseInt(response.data.code)) {
          // 更新列表
          get_list()
        } else {
          if(response.data.code !== undefined){message.error(i18n.t("errorMsg." + response.data.code));}
        }
      }).finally(()=>{
        loading.value = false
      })
    },
  });
}

function search_reset() {
  searchForm.value = {}
  get_list()
}

// 设置页标题
store.commit("setPageTitle", i18n.t('nav.name.ChargingStationManage'));
get_list()

defineExpose({
  get_list
})
</script>

<style lang="less">
@import url("~@/views/Home/chargingStationSetting/chargingStationManage/style.less");
</style>
