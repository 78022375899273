import {Base} from "@/api/Base"

const api = "elaLogs"

export class ElaLogsFindLoadBalancedLogs extends Base {
    path = api + "/findLoadBalancedLogs"
}

export class ElaLogsFindLoadBalancedLogByIdMet extends Base {
    path = api + "/findLoadBalancedLogByIdMet"
}

export class ElaLogsFindOcppLog extends Base {
    path = api + "/findOcppLog"
}