<template>
  <Row id="PageContent">
    <!-- 加载状态-->
    <Spin size="large" :spinning="false"/>
    <Col :span="24" class="content">
      <!-- 查询表单-->
      <Row :gutter="[16,32]">
        <Col :span="24">
          <Form :model="searchForm">
            <Row :gutter="16">
              <Col :span="6">
                <FormItem :label="$t('noticeManage.field.toFrom')">
                  <Input :placeholder="$t('noticeManage.validate.toFrom')" v-model:value="searchForm.accountNumber" allowClear></Input>
                </FormItem>
              </Col>
              <Col :span="6">
                <FormItem :label="$t('hsbc.valueDateTime')">
                  <DatePicker v-model:value="searchForm.transactionDate" format="YYYY-MM-DD" valueFormat="YYYY-MM-DD" style="width: 100%;" />
                </FormItem>
              </Col>
<!--              <Col :span="6">-->
<!--                <FormItem :label="$t('noticeManage.field.toFrom')">-->
<!--                  <Input :placeholder="$t('noticeManage.validate.toFrom')" v-model:value="searchForm.accountCountry" allowClear></Input>-->
<!--                </FormItem>-->
<!--              </Col>-->
<!--              <Col :span="6">-->
<!--                <FormItem :label="$t('noticeManage.field.toFrom')">-->
<!--                  <Input :placeholder="$t('noticeManage.validate.toFrom')" v-model:value="searchForm.accountType" allowClear></Input>-->
<!--                </FormItem>-->
<!--              </Col>-->
            </Row>
          </Form>
          <Row style="margin-bottom: 5px">
            <Col :span="8">
<!--              <Button type="primary" size="default" @click="add()">{{ $t('public.name.add') }}</Button>-->
<!--              <span>&nbsp;</span>-->
<!--              <Button type="primary" size="default" danger @click="delByIds()" :disabled="selectedRowKeys === undefined || selectedRowKeys.length === 0">{{ $t('public.name.batchDel') }}</Button>-->
            </Col>
            <Col :span="8" :offset="8" style="text-align: right;">
              <Button type="primary" @click="search_submit">{{$t('public.name.search')}}</Button>
              <span>&nbsp;</span>
              <Button @click="search_reset">{{$t('public.name.reset')}}</Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <div>
        <Card :title="identification">
          <template v-for="(it) in list" :key="it.type">
            <CardGrid style="width: 25%">
              <Descriptions :title="$t('hsbc.' + it.type)" :column="1">
                <DescriptionsItem :label="$t('hsbc.amount')">{{ it.amount.amount + ' ' + it.amount.currency }}</DescriptionsItem>
                <DescriptionsItem :label="$t('hsbc.creditLine')">{{ it.creditLine[0].amount.amount + ' ' + it.creditLine[0].amount.currency }}</DescriptionsItem>
                <DescriptionsItem :label="$t('hsbc.dateTime')">{{ it.dateTime?.replace("T", "") }}</DescriptionsItem>
                <!--                  <DescriptionsItem label="dateTime">{{ moment(it.dateTime).format('YYYY-MM-DD HH:mm:ss') === 'Invalid date' ?  it.dateTime.replace("T", "") : moment( it.dateTime).format('YYYY-MM-DD HH:mm:ss') }}</DescriptionsItem>-->
                <!--                  <DescriptionsItem label="creditDebitIndicator">{{ it.creditDebitIndicator }}</DescriptionsItem>-->
                <!--                  <DescriptionsItem label="type">{{ it.type }}</DescriptionsItem>-->
                <!--                  <DescriptionsItem label="included">{{ it.creditLine[0].included }}</DescriptionsItem>-->
              </Descriptions>
            </CardGrid>
          </template>
        </Card>

        <Table
            :scroll="{x: 1500}"
            :columns="columns"
            :dataSource="dataList"
            rowKey="id"
            :loading="loading"
            :pagination="false"
        >
        </Table>
      </div>
    </Col>
  </Row>
</template>

<script>
export default {
  name: "NoticeManage"
}
</script>
<script setup>
import {createVNode, reactive, ref, toRefs} from "vue";
import {
  Button,
  Col,
  Form,
  FormItem,
  Input,
  message,
  Modal,
  Row,
  Select,
  SelectOption,
  Card,
  CardGrid,
  Spin,
  Descriptions,
  DescriptionsItem,
  Table,
  Switch,
  DatePicker
} from "ant-design-vue";
import moment from "moment";
import {DownOutlined, ExclamationCircleOutlined, PlusOutlined} from "@ant-design/icons-vue";
import {NoticeGetListByPage, NoticeDelete} from "@/api/notice";
import FormModel from "./FormModel";
import {useStore} from "vuex";
import {useI18n} from "vue-i18n";
import {HsbcBalances, HsbcTransactions} from "@/api/hsbc";
import dayjs from 'dayjs';

const store = useStore()
const i18n = useI18n()

let refFormModel = ref()

let value = ref()
let loading = ref(false)
let isClickLoading = ref(false)
const options = [
  {label: i18n.t("noticeManage.noticeType.0"), value: '0'},
  {label: i18n.t("noticeManage.noticeType.1"), value: '1'},
  {label: i18n.t("noticeManage.noticeType.2"), value: '2'},
]
const columns = [
  { title: i18n.t("iPadManage.field.index"), fixed: "center", width: 60, customRender: ({text, record, index, column}) => `${index + 1}`},
  { title: i18n.t("hsbc.transactionReference"), fixed: "center", width: 150, dataIndex: "transactionReference", key: "transactionReference" },
  { title: i18n.t("hsbc.statementReference"), fixed: "center", width: 100, dataIndex: "statementReference", key: "statementReference" },
  { title: i18n.t("hsbc.creditDebitIndicator"), fixed: "center", width: 50, dataIndex: "creditDebitIndicator", key: "creditDebitIndicator" },
  { title: i18n.t("hsbc.reversalIndicator"), fixed: "center", width: 100, dataIndex: "reversalIndicator", key: "reversalIndicator" },
  { title: i18n.t("hsbc.transactionStatus"), fixed: "center", width: 100, dataIndex: "transactionStatus", key: "transactionStatus" },
  { title: i18n.t("hsbc.bookingDateTime"), fixed: "center", width: 100, dataIndex: "bookingDateTime", key: "bookingDateTime", customRender: ({text}) => {
      return moment(text).format('YYYY-MM-DD HH:mm:ss')
    }},
  { title: i18n.t("hsbc.valueDateTime"), fixed: "center", width: 100, dataIndex: "valueDateTime", key: "valueDateTime", customRender: ({text}) => {
      return moment(text).format('YYYY-MM-DD')
    }},
  { title: i18n.t("hsbc.transactionInformation"), fixed: "center", width: 100, dataIndex: "transactionInformation", key: "transactionInformation" },
  { title: i18n.t("hsbc.transactionAmount"), fixed: "center", width: 100, dataIndex: "transactionAmount", key: "transactionAmount", customRender: ({text}) => {
      return text.amount + text.currency
    }},
  { title: i18n.t("hsbc.bankTransactionCode"), fixed: "center", width: 100, dataIndex: "bankTransactionCode", key: "bankTransactionCode", customRender: ({text}) => {
      return text.code + '/' + text.subcode + '/' + text.domncode
    }},
  { title: i18n.t("hsbc.proprietaryBankTransactionCode"), fixed: "center", width: 100, dataIndex: "proprietaryBankTransactionCode", key: "proprietaryBankTransactionCode", customRender: ({text}) => {
      return text.code + '/' + text.issuer
    }},
]
let identification = ref("")
const data = reactive({
  searchForm: {
    accountNumber: '388478109001',
    // transactionDate: ref(dayjs('2024-05-08', 'YYYY-MM-DD')),
    transactionDate: dayjs().format('YYYY-MM-DD'),
    accountCountry: 'HK',
    accountType: 'CA',
  },
  list: [],
  dataList: [],
  selectedRowKeys: []
})
const {searchForm, list, dataList, selectedRowKeys} = toRefs(data)


function get_list() {
  loading.value = true;
  const api = new HsbcTransactions();
  // 构建查询参数
  const getData = {
    ...searchForm.value
  }
  api.get(getData).then((response) => {
    loading.value = false;
    if (0 === parseInt(response.data.code)) {
      const dataTmp = response.data.data;
      list.value = dataTmp.transactions.balance;
      identification.value = dataTmp.transactions.scheme.identification

      let data = []
      dataTmp.transactions?.transaction?.forEach(item => {
        data.push(item.items)
      })
      dataList.value = data
    } else {
      list.value = [];

    }
  });
}

function add() {
  refFormModel.value?.addShow();
}

function edit(obj) {
  refFormModel.value?.editShow(obj);
}

function onSelectChange(ids) {
  selectedRowKeys.value = ids
}

function del(obj) {
  Modal.confirm({
    title: i18n.t("public.name.are_you_sure_delete") + "?",
    content: i18n.t("public.name.action_cannot_resumed"),
    icon: createVNode(ExclamationCircleOutlined),
    okText: i18n.t("public.name.yes"),
    okType: "danger",
    cancelText: i18n.t("public.name.no"),
    onOk: () => {
      const api = new NoticeDelete();
      let l = []
      l.push(obj.id)
      api.post({ids: l}).then((response) => {
        if (0 === response.data.code) {
          message.success(i18n.t("succeedMsg.0"))
        } else {
          message.error(i18n.t("errorMsg." + response.data.code))
        }
        // 刷新列表
        get_list();
      });
    },
  });
}

function delByIds() {
  Modal.confirm({
    title: i18n.t("public.name.are_you_sure_delete") + "?",
    content: i18n.t("public.name.action_cannot_resumed"),
    icon: createVNode(ExclamationCircleOutlined),
    okText: i18n.t("public.name.yes"),
    okType: "danger",
    cancelText: i18n.t("public.name.no"),
    onOk: () => {
      const api = new NoticeDelete();
      api.post({ids: selectedRowKeys.value}).then((response) => {
        if (0 === response.data.code) {
          message.success(i18n.t("succeedMsg.0"))
        } else {
          message.error(i18n.t("errorMsg." + response.data.code))
        }
        // 刷新列表
        get_list();
      });
    },
  });
}

function search_submit() {
  get_list()
}

function search_reset() {
  value.value = undefined
  searchForm.value = {}
  get_list()
}

function handleChange(v) {
  value.value = v
  let a = ''
  for (let i=0; v.length > i; i++) {
    a = a + v[i] + ','
  }
  if (a.endsWith(',')) {
    a = a.substring(0, a.length-1)
  }
  searchForm.value.noticeType = a
}

// 设置页标题
store.commit("setPageTitle", i18n.t('nav.name.HsbcManage'));
get_list();

defineExpose({
  get_list
})

</script>
