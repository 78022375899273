<template>
  <div class="body">
    <div class="header">
      <img src="https://img.icons8.com/fluent/48/000000/checkmark.png" alt="支付成功">
      <h1>支付成功</h1>
    </div>
    <div style="height: auto">
      <div class="amount">${{ params['amount'] }}</div>
      <div class="order-id">支付編號: {{ params['orderId'] }}</div>
      <div class="buttons">
        <!--      <button class="home-btn" onclick="port.postMessage('paySuccess');">返回首頁</button>-->
        <button id="myButton" class="home-btn" type="button" @click="ntfFlutter()" :disabled="countdown > 0"> {{ countdown > 0 ? "返回首頁(" + countdown + "s)" : "返回首頁" }}</button>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "MpgsSuccess",
}
</script>
<script setup>
import {Row, Col} from "ant-design-vue";
import {onMounted, onUnmounted, ref} from "vue";

let port = ref();
let paySuccess = ref("");

let timer =ref()
let countdown =ref(5)

let params = ref({})
function getParam() {
  let params = {};
  let href = window.location.href;
  let query = href.split("?")
  query.map(item => {
    let vars = item.split('&');
    for (let i = 0; i < vars.length; i++) {
      let pair = vars[i].split('=');
      params[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1]);
    }
  })
  return params;
}

function ntfFlutter() {
  // 调用 Flutter 的 JavaScript handler
  window.flutter_inappwebview.callHandler('paymentCallback', {"msg":"支付成功","code":0,"price":5000});
}

function eventFunt (event) {
  if (event.data === 'capturePort') {
    // capture port2 coming from the Dart side
    if (event.ports[0] != null) {
      // the port is ready for communication,
      // so you can use port.postMessage(message); wherever you want
      port.value = event.ports[0];
      // To listen to messages coming from the Dart side, set the onmessage event listener
      port.value.onmessage = function (event) {
        // event.data contains the message data coming from the Dart side
        console.log(event.data);
      };
    }
  }
}

onMounted(() => {
  timer.value = setInterval(() => {
    // 倒计时减一
    countdown.value--;
    // 如果倒计时结束，则清除定时器
    if (countdown.value <= 0) {
      clearInterval(timer.value);
    }
  }, 1000); // 每秒执行一次

  // window.addEventListener('message', eventFunt, false);
  params.value = getParam()
  console.log("params.value = ", params.value)
})
onUnmounted(()=>{
  window.removeEventListener('message', eventFunt)
  clearInterval(timer.value);
})

</script>

<style scoped>
.body {
  height: 100vh; /* 视口高度的100% */
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f6f7f9;
  text-align: center;
}

.header {
  background-color: #ff9800;
  padding: 40px 20px;
  color: #fff;
}

.header img {
  width: 50px;
  height: 50px;
  margin-bottom: 10px;
}

.header h1 {
  font-size: 20px;
  margin: 10px 0 0;
  font-weight: normal;
}

.amount {
  font-size: 36px;
  color: #333;
  margin: 20px 0;
}

.order-id {
  color: #999;
  font-size: 14px;
  margin-bottom: 30px;
}

.buttons {
  display: flex;
  justify-content: space-around;
  padding: 0 20px;
  margin-bottom: 20px;
}

.buttons button {
  padding: 10px 20px;
  border-radius: 20px;
  border: 2px solid transparent;
  font-size: 16px;
  cursor: pointer;
  outline: none;
  width: 45%;
}

.buttons .home-btn {
  border-color: #ff9800;
  background-color: #fff;
  color: #ff9800;
}

.buttons .order-btn {
  background-color: #ff9800;
  color: #fff;
}

.login-btn {
  display: block;
  background-color: #00c853;
  color: #fff;
  border-radius: 20px;
  padding: 10px 0;
  font-size: 16px;
  text-decoration: none;
  margin: 0 20px;
}
</style>
