<template>
  <Row id="PageContent">
    <Col :span="24" class="content">
      <Row :gutter="[16,32]">
        <Col :span="24">
          <Form :model="searchForm">
            <Row :gutter="16">
              <Col :span="6">
                <FormItem :label="$t('meter.table.id_reg')" name="idReg">
                  <Select
                      v-model:value="searchForm.idReg"
                      show-search
                      :placeholder="$t('public.pleaseSelect')"
                      style="width: 100%"
                      :options="options"
                      :filter-option="filterOption"
                      allowClear
                  ></Select>
                </FormItem>
              </Col>
              <Col :span="6">
                <FormItem :label="$t('meter.table.name')" name="name">
                  <Input :placeholder="$t('meter.validate.required.name')" v-model:value="searchForm.name"></Input>
                </FormItem>
              </Col>
              <Col :span="6">
                <FormItem :label="$t('meter.table.current_threshold')" name="currentThreshold">
                  <Input :placeholder="$t('meter.validate.required.current_threshold')"
                         v-model:value="searchForm.currentThreshold"></Input>
                </FormItem>
              </Col>
              <Col :span="6"></Col>
            </Row>
          </Form>
          <Row style="margin-bottom: 5px">
            <Col :span="8"></Col>
            <Col :span="8" :offset="8" style="text-align: right;">
              <Button type="primary" @click="search_submit">{{ $t('public.name.search') }}</Button>
              <span>&nbsp;</span>
              <Button @click="search_reset">{{ $t('public.name.reset') }}</Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <div>
        <Table :columns="columns" :dataSource="list" :scroll="{x: 1500}" rowKey="idMet" :loading="loading" :pagination="tablePagination" @change="chang_page">
          <!-- 标题插槽-->
          <template #headerCell="{ column }">
            <template v-if="column.dataIndex === 'action'">
              <span>
                {{ $t("public.table.action") }}
                <Button v-if="checkPermission('MeterManage.Add')" type="primary" size="small" shape="circle" @click="add()">
                  <PlusOutlined/>
                </Button>
              </span>
            </template>
          </template>

          <!-- 内容插槽-->
          <template #bodyCell="{column, record}">

            <template v-if="column.dataIndex === 'isEnableSwitch'">
              <Switch :checked="record.status === 1" @click="e => switchClick(e, record.idMet)" checked-children="ON" un-checked-children="OFF" />
            </template>

            <template v-if="column.dataIndex === 'action'">
              <div>
                <div class="pc-display">
                  <Button v-if="record.loadByTactics === 1" type="default" size="small" @click="meterDashShow(record.idMet)">{{ $t("nav.name.Dashboard") }}</Button>
                  <span>&nbsp;</span>
                  <Button type="default" size="small" @click="detail(record)">{{ $t("public.name.detail") }}</Button>
                  <span>&nbsp;</span>
                  <Button v-if="checkPermission('MeterManage.Edit')" type="primary" size="small" @click="edit(record)">{{ $t("public.name.edit") }}</Button>
                  <span>&nbsp;</span>
                  <Button v-if="checkPermission('MeterManage.Delete')" type="primary" danger size="small" @click="del(record.idMet)">{{ $t("public.name.del") }}</Button>
                </div>
              </div>
            </template>
          </template>
        </Table>
      </div>
    </Col>
    <MeterDetail ref="refMeterDetail"></MeterDetail>
    <MeterEdit ref="refMeterEdit" @updateList="get_list" :options="options"></MeterEdit>
    <MeterDash ref="refMeterDash"></MeterDash>
  </Row>
</template>
<script>
export default {
  name: "MeterManage",
}
</script>
<script setup>
import {createVNode, reactive, ref, toRefs} from "vue";
import {
  Row,
  Col,
  Table,
  Button,
  Modal,
  Form,
  FormItem,
  Input,
  Select,
  message,
  Switch
} from "ant-design-vue";
import {ExclamationCircleOutlined, PlusOutlined} from "@ant-design/icons-vue";
import {MeterList, MeterDelete, MeterSetStatus} from "@/api/meter";
import {RegionGetSelectList} from "@/api/region";
import MeterDetail from "./MeterDetail";
import MeterEdit from "./MeterEdit";
import MeterDash from "./MeterDash";
import {useStore} from "vuex";
import {useI18n} from "vue-i18n";
import {useRoute} from "vue-router/dist/vue-router";

const store = useStore()
const i18n = useI18n()
const route = useRoute()

let value = ref()
let loading = ref(false)

const refMeterDetail = ref()
const refMeterEdit = ref()
const refMeterDash = ref()

const columns = [
  {
    title: i18n.t("public.name.index"),
    fixed: "left",
    width: 60,
    ellipsis: true,
    customRender: ({text, record, index, column}) => `${(tablePagination.value.current-1) * tablePagination.value.pageSize +  index + 1}`
  },
  { title: i18n.t("superTenantManage.table.isEnable"), key: "status", fixed: "center", width: 100, dataIndex: "isEnableSwitch" },
  {title: i18n.t("meter.table.id_met"), width: 60, ellipsis: true,  dataIndex: "idMet", key: "idMet"},
  {title: i18n.t("meter.table.id_reg"), width: 180, ellipsis: true, dataIndex: "regionName", key: "regionName"},
  {title: i18n.t("meter.table.name"), width: 180, ellipsis: true, dataIndex: "name", key: "name"},
  {title: "Current", width: 90, ellipsis: true, dataIndex: "current", key: "current"},
  {title: i18n.t("meter.table.current_threshold"), width: 90, ellipsis: true, dataIndex: "currentThreshold", key: "currentThreshold"},
  {title: i18n.t("meter.table.tenant_key"), width: 80, ellipsis: true, dataIndex: "tenantKey", key: "tenantKey"},

  {title: i18n.t("meter.table.loadByTactics"), width: 60, ellipsis: true, dataIndex: "loadByTactics", key: "loadByTactics",
    customRender: ({text}) => {
      if (text === 1) {
        return i18n.t("public.isTrue.1")
      } else {
        return i18n.t("public.isTrue.0")
      }
    }
  },
  {key: "action", fixed: "right", width: 180, ellipsis: true, dataIndex: "action"},
]

const data = reactive({
  options:[],
  searchForm: {},
  list: [],
  tablePagination: {
    pageSizeOptions: ["10", "20", "50", "100"],
    showSizeChanger: true,
    current: 1,
    pageSize: 10,
    total: 0,
  },
  regionList: [],
})
const {options, searchForm, list, tablePagination, regionList} = toRefs(data)

function chang_page(pagination) {
  tablePagination.value = pagination;
  get_list();
}

function get_list() {
  loading.value = true;
  // 获取下拉列表
  getRegionSelectList()

  if (route.query.idReg) {
    searchForm.value.idReg = parseInt(route.query.idReg)
  }

  const api = new MeterList();
  const getData = {
    ...searchForm.value,
    page: tablePagination.value.current,
    limit: tablePagination.value.pageSize
  }

  api.get(getData).then((response) => {
    loading.value = false;
    if (0 === parseInt(response.data.code)) {
      const dataTmp = response.data.data;
      tablePagination.value.current = parseInt(dataTmp.current);
      tablePagination.value.pageSize = parseInt(dataTmp.pageSize);
      tablePagination.value.total = parseInt(dataTmp.total);
      list.value = dataTmp.data;
    } else {
      list.value = [];
    }
  });
}

function detail(obj) {
  refMeterDetail.value?.detailShow(obj);
}

function meterDashShow(idMet) {
  refMeterDash.value?.detailShow(idMet);
}

function add(idReg) {
  refMeterEdit.value?.addShow(idReg, options.value);
}

function edit(obj) {
  refMeterEdit.value?.editShow(obj, options.value);
}

function del(idMet) {
  Modal.confirm({
    title: i18n.t("public.name.are_you_sure_delete") + "?",
    content: i18n.t("public.name.action_cannot_resumed"),
    icon: createVNode(ExclamationCircleOutlined),
    okText: i18n.t("public.name.yes"),
    okType: "danger",
    cancelText: i18n.t("public.name.no"),
    onOk: () => {
      const api = new MeterDelete();
      api.post({idMet: idMet}).then((response) => {
        if (0 === parseInt(response.data.code)) {
          get_list();
          message.success(i18n.t("succeedMsg.0"));
        } else {
          if(response.data.code !== undefined){message.error(i18n.t("errorMsg." + response.data.code));}
        }
      });
    },
  });
}

function search_submit() {
  tablePagination.value.current = 1
  get_list()
}

function search_reset() {
  searchForm.value = {}
  get_list()
}

function filterOption(input, option) {
  return option.label.indexOf(input) >= 0;
}

// 获取所有区域 id-name 列表
function getRegionSelectList() {
  const api = new RegionGetSelectList
  api.get().then(res => {
    if (0 === parseInt(res.data.code)) {
      options.value = res.data.data
    }
  })
}

function switchClick(e, id) {
  Modal.confirm({
    title: i18n.t("public.name.are_you_sure_operate") + "?",
    content: i18n.t("public.name.operate_remind") + "！",
    icon: createVNode(ExclamationCircleOutlined),
    okText: i18n.t("public.name.yes"),
    okType: "danger",
    cancelText: i18n.t("public.name.no"),
    onOk: () => {
      loading.value = true
      // 更新数据库及刷新列表
      const api = new MeterSetStatus()
      api.post({id: id, isEnable: e}).then(response=>{
        if (0 === parseInt(response.data.code)) {
          // 更新列表
          get_list()
        } else {
          if(response.data.code !== undefined){message.error(i18n.t("errorMsg." + response.data.code));}
        }
      }).finally(()=>{
        loading.value = false
      })
    },
  });
}

// 设置页标题
store.commit("setPageTitle", i18n.t('nav.name.MeterManage'));
get_list();

defineExpose({
  get_list
})
</script>

<style lang="less">
@import url("style.less");
</style>
