import {Axios, Base} from "@/api/Base"

const api = "unit"

export class UnitGetTree extends Base {
    path = api + "/getTree"
}

export class UnitInfoByUserId extends Base {
    path = api + "/getInfoByUserId"
}

export class UnitGetUnitIdsByUserId extends Base {
    path = api + "/getUnitIdsByUserId"
}