<template>
  <Modal
      id="BalRefundEdit"
      width="50%"
      :open="visible"
      @cancel="visible = !visible"
      @ok="submit"
      :title="$t('public.name.query')"
  >
    <Row justify="center" >
      <Col span="18">
        <Row>
          <Col span="24">
            <Form
                ref="orderForm"
                :model="form"
                layout="vertical"
                :rules="validateRules"
            >
              <Row justify="space-around">

                <Col span="10">
                  <FormItem :label="$t('order.table.id_ord')" name="idOrd">
                    <Input
                        v-model:value="form.idBalLog"
                        :placeholder="$t('order.validate.id_ord')"
                        disabled
                    />
                  </FormItem>
                </Col>

                <Col span="10">
                  <FormItem :label="$t('user.table.account')" name="account">
                    <Input v-model:value="form.userAccount" disabled/>
                  </FormItem>
                </Col>

                <Col span="10">
                  <FormItem :label="$t('order.table.amount')" name="amount">
                    <InputNumber
                        v-model:value="form.amount"
                        :placeholder="$t('order.validate.amount')"
                        :formatter="value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
                        :parser="value => value.replace(/\$\s?|(,*)/g, '')"
                        :min="0" :step="0.01"
                        style="width: 100%"
                        disabled
                    />
                  </FormItem>
                </Col>

                <Col span="10">
                  <FormItem :label="$t('order.table.status')" name="status">
                    <Select
                        v-model:value="form.status"
                        :placeholder="$t('order.validate.status')"
                        :options="statusList"
                        :dropdownStyle="{ zIndex: 9999999950 }"
                        disabled
                    >
                    </Select>
                  </FormItem>
                </Col>
                <Col span="22">
                  <FormItem :label="$t('public.name.remark')" name="remark">
                    <Textarea
                        v-model:value="resDate"
                        :placeholder="$t('employee.validate.required.remark')"
                        :auto-size="{ minRows: 5, maxRows: 10 }"
                        showCount
                    />
                  </FormItem>
                </Col>

              </Row>
            </Form>
          </Col>
        </Row>
      </Col>
    </Row>
  </Modal>
</template>
<script>
export default {
  name: "BalRefundEdit",
}
</script>
<script setup>
import {
  Modal,
  Row,
  Col,
  Form,
  FormItem,
  Input,
  Textarea,
  InputNumber,
  Select, message,
} from "ant-design-vue";
import {BalanceQuery} from "@/api/balanceLog";
import {reactive, ref, toRefs} from "vue";
import {useI18n} from "vue-i18n"
import {useStore} from "vuex";

const store = useStore()
const i18n = useI18n()
const emits = defineEmits(['updateList'])

let visible = ref(false)
let actionType = ref("")
let title = ref("")
let resDate = ref("")

const orderForm = ref()

const typeList = [
  {value: 1, label: i18n.t("public.chargeType.0")},
  {value: 2,label: i18n.t("public.chargeType.1")},
  {value: 3, label: i18n.t("public.chargeType.2")}
]
const statusList = [{value: 1,label: i18n.t("public.payStatus.0")},{value: 2,label: i18n.t("public.payStatus.3")}]
const payTypeList = [{value: 1, label: 'Online Pay'}, {value: 2, label: 'Balance'}]
const validateRules = {
  remark: [
    {
      required: true,
      message: i18n.t("employee.validate.required.remark"),
      trigger: "change",
    },
  ],
}

const data = reactive({
  form: {},
})
const {form} = toRefs(data)

async function editShow(obj) {
  _resetData();
  actionType.value = "edit";
  form.value = JSON.parse(JSON.stringify(obj));
  visible.value = true;

  // 获取支付信息
  const api = new BalanceQuery()
  api.get({orderNo: obj.orderNo}).then(response => {
    if (0 === parseInt(response.data.code)) {
      resDate.value = JSON.stringify(response.data.data)
    } else {
      if(response.data.code !== undefined){message.error(i18n.t("errorMsg." + response.data.code));}
    }
  })
}

function submit() {
  visible.value = false;
}

function _resetData() {
  resDate.value = ""
  form.value = {};
}

defineExpose({
  editShow
})
</script>
