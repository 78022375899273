const msMY = {
    'succeedMsg': {
        '0': 'Berjaya',
    },
    'errorMsg': {
        '500100': 'Pengecualian sisi pelayan',
        '500101': 'Ralat parameter',
        '500102': 'Pengecualian Pengesahan Parameter',
        '500103': 'The request is illegal',
        '500104': 'Lawatan terlalu kerap',
        '500105': 'Object already exists',
        '500106': 'The operation failed, please try again',
        '500107': 'Ada persatuan, penghapusan dilarang',
        '500108': 'Pengecualian eksport',
        '500200': 'Login expired, please log in again',
        '500201': 'The account number or password cannot be empty',
        '500202': 'Nombor telefon tidak boleh kosong',
        '500203': 'The phone number is in the wrong format',
        '500204': 'The phone number does not exist',
        '500205': 'The password is incorrect',
        '500206': 'Akaun tidak wujud',
        '500207': 'Account numbers can only contain numbers, English, and underscores',
        '500208': 'Authentication failed',
        '500209': 'Kedua -dua kata laluan tidak sama',
        '500210': 'Duplicate accounts',
        '500211': 'The account has been locked',
        '500212': 'Captcha failed',
        '500213': 'The phone number is wrong',
        '500214': 'Tidak ada penyewa yang sepadan dengan nama domain, hubungi pentadbir anda',
        '500215': 'ada nama peranan yang sama',
        '500300': 'Sekiranya perkhidmatan OCCP tidak normal, hubungi pentadbir',
        '500301': 'Electric piles are not available',
        '500302': 'The pile configuration is empty',
        '500303': 'The battery is offline, please check if it is available',
        '500304': 'The pile port is not available',
        '500305': 'The gun is not inserted or the current gun is not ready',
        '500306': 'Mula mengecas kegagalan',
        '500307': 'Kegagalan mengecas',
        '500401': 'Insufficient balance',
        '500402': 'Pesanan tidak wujud',
        '500403': 'Pesanan itu tidak dibayar',
        '500404': 'Pesanan telah dibayar',
        '500405': 'Status pesanan tidak [dibayar tidak digunakan]',
        '500501': 'Item telah dijual dalam beberapa saat',
        '500502': 'Tawaran kilat tidak dapat diulang',
        '500503': 'Second kill failure',
        '500601': 'Fail tidak boleh kosong',
        '500602': 'Ralat format fail',
        '500603': 'Buku Kerja Excel kosong',
        '500604': 'Gagal membaca fail'
    },
    'public': {
        'name': {
            'nextStep': 'Langkah berikutnya',
            'status': 'Negeri',
            'hello': 'Helo',
            'login': 'Log masuk',
            'logout': 'Log keluar',
            'username': 'Nama pengguna',
            'email': 'E-mel',
            'password': 'Kata laluan',
            'remember': 'Ingat saya',
            'currentPassword': 'Kata Laluan Semasa',
            'newPassword': 'Kata Laluan Baru',
            'confirm_password': 'Sahkan kata laluan',
            'reset_password': 'Menetapkan semula kata laluan',
            'captcha': 'Captcha',
            'getCaptcha': 'Dapatkan Captcha',
            'more': 'Lebih',
            'add': 'Tambah',
            'batchDel': 'Batch DELETE',
            'Export': 'Eksport',
            'Import': 'Import',
            'DownloadTemp': 'Muat Turun Templat',
            'ImportTips': 'Klik atau seret fail ke kawasan ini untuk muat naik',
            'edit': 'Edit',
            'del': 'Padam',
            'detail': 'Perincian',
            'yes': 'Ya',
            'no': 'Tidak',
            'empty': 'Kosong',
            'are_you_sure_delete': 'Adakah anda pasti mahu memadamkan item ini',
            'action_cannot_resumed': 'Tindakan ini tidak dapat disambung semula',
            'are_you_sure_operate': 'Anda pasti mengenai operasi semasa',
            'operate_remind': 'Operasi semasa sensitif, sila berhati-hati',
            'search': 'Cari',
            'reset': 'Tetapkan semula',
            'spread': 'Sebar',
            'retract': 'Menarik balik',
            'show': 'Tunjukkan',
            'no_page': 'Tiada halaman',
            'service': 'Perkhidmatan',
            'phone_country_code': 'Kod negara telefon',
            'name': 'Nama',
            'phone': 'Telefon',
            'sex': 'Seks',
            'country': 'Negara',
            'address': 'Alamat',
            'birthday': 'Ulang tahun',
            'remark': 'Catatan',
            'personal': 'Peribadi',
            'enclosure': 'Kandang',
            'logo': 'Logo',
            'submit': 'Hantar',
            'unknown': 'Tidak diketahui',
            'setting': 'Menyediakan',
            'index': 'Nombor siri',
            'createBy': 'Buat oleh',
            'createTime': 'Buat masa',
            'updateBy': 'Kemas kini oleh',
            'updateTime': 'Masa kemas kini',
            'type': 'Jenis',
            'args': 'Parameter',
            'qrcode': 'Kod QR',
            'query': 'Query',
            'refund': 'Kembalikan',
            'cancel': 'Batalkan',
            'revoke': 'Batalkan',
        },
        'msg': {
            'unRead': 'Tidak dibaca',
            'read': 'Baca',
            'alarm': 'Alarm',
            'reminder': 'Penggera',
            'advertise': 'Comment'
        },
        'language': {
            'zhTW': 'Cina (tradisional)',
            'enUS': 'Bahasa Inggeris',
            'zhCN': 'Cina (dipermudahkan)',
            'daDK': 'Bahasa Denmark',
            'idID': 'Bahasa Indonesia',
            'msMY': 'Bahasa Melayu',
            'thTH': 'Bahasa Thai',
            'viVN': 'Bahasa Vietnam',
            'frFR': 'Bahasa Perancis',
            'plPL': 'Polski',
            'it': 'Itali',
        },
        'timeType': {
            0: 'Tahun',
            1: 'Bulan',
            2: 'Minggu',
            3: 'Hari',
        },
        'promptsToSelectTime': 'Sila pilih masa',
        'pleaseSelect': 'Sila pilih',
        'pileStatus': {
            0: 'Avaliable',
            1: 'Mengecas',
            2: 'Tidak tersedia',
        },
        'isTrue': {'0': 'Tidak', '1': 'Ya'},
        'chargerType': {'0': 'AC', '1': 'DC'},
        'registerType': {'0': 'E -mel', '1': 'Telefon'},
        'doingStatus':{
            0: 'Menunggu',
            1: 'Memproses',
            2: 'Selesai',
            3: 'Batalkan',
            4: 'Mati',
            5: 'Penghentian',
        },
        'consumeType': {
            0: "Muat Semula",
            1: "Memuatkan Konsum",
            2: "Memuatkan Pembatalan",
            3: "Imbangan",
            4: 'Rancangan Pembelian',
            5: 'Batalkan Rancangan',
            6: 'Rencana Pembaharuian',
            7: 'Kembalikan',
            8: 'Nota hukuman',
            9: 'Batal denda',
            10: 'Pengunduran',
            11: 'Hadiah penuh',
            12: 'Batalkan',
            13: 'Daftar Bulan',
        },
        'payStatus': {'0': 'Tidak dibayar', '1': 'Dibayar', '2': 'Mengecas', '3': 'Selesai', '4':'Batal'},
        'chargeType': {'0': 'Pada masa', '1': 'Menurut perintah', '2': 'Oleh rancangan pengguna'},
        'status': {'0': 'Tidak diketahui', '1': 'Biasa', '2': 'Lumpuhkan', '3': 'Padam'},
        'sex': {'1': 'Lelaki', '2': 'Wanita', '3': 'Tidak diketahui'},
        'placeholder': {'please_select': 'Sila pilih'},
        'table': {
            'name': 'Nama',
            'status': 'Negeri',
            'action': 'Tindakan',
            'content': 'Kandungan',
            'created_at': 'Buat masa',
            'image': 'Imej',
            'email': 'E-mel',
            'order_id': 'Pesanan',
            'sex': 'Seks',
            'last_ip': 'IP terakhir',
            'phone_country_code': 'Sila pilih kod negara telefon',
            'phone': 'Telefon'
        },
        'field': {
            'name': 'Nama',
            'username': 'Nama pengguna',
            'content': 'Kandungan',
            'phone_country_code': 'Kod negara telefon',
            'phone': 'Telefon',
            'email': 'E-mel',
            'order_id': 'Pesanan',
            'portrait': 'Potret',
            'status': 'Negeri',
            'image': 'Imej',
            'sex': 'Seks'
        },
        'validate': {
            'required': {
                'id': 'Kekurangan ID',
                'username': 'Sila masukkan nama pengguna',
                'password': 'Sila masukkan kata laluan',
                'repassword': 'Sila ulangi kata laluan',
                'name': 'Sila Nama Kemasukan',
                'phone': 'Sila masuk telefon',
                'email': 'Sila masukkan e-mel',
                'portrait': 'Sila muat naik potret'
            },
            'tips': {
                'inconsistent_password': 'Kata laluan yang tidak konsisten',
                'length_should': 'Panjang mestilah {min} ke {max}'
            }
        }
    },
    'language': {
        'zhTW': 'Cina (tradisional)',
        'enUS': 'Bahasa Inggeris',
        'zhCN': 'Cina (dipermudahkan)',
        'daDK': 'Bahasa Denmark',
        'idID': 'Bahasa Indonesia',
        'msMY': 'Bahasa Melayu',
        'thTH': 'Bahasa Thai',
        'viVN': 'Bahasa Vietnam',
        'frFR': 'Bahasa Perancis',
        'it': 'Itali',
    },
    'nav': {
        'name': {
            'Dashboard': 'Papan pemuka',
            'RegionDashboard': 'Paparan ringkasan tumpukan',
            'BigScreen': 'Skrin',
            'AuthorityManage': 'Pihak Berkuasa Mengurus',

            'BalanceLogManage': 'Pengurusan Log Baki',
            'CarManage': 'Kereta menguruskan',
            'CarImagesManage': 'Kereta ImagesManage',
            'ChargeStationImagesManage': 'Caj Point Imagemanage',
            'ChargingStationManage': 'Caj Ptitik Menguruskan',
            'ConnectorManage': 'Penyambung menguruskan',
            'EmployeeManage': 'Mengurus Operator',
            'ConnectorLogManage': 'Log Sambungan',
            'EventLogManage': 'Pengurusan Log Acara',
            'MeterManage': 'Meter menguruskan',
            'OrderManage': 'Urus pesanan',
            'PlanManage': 'Rancangan menguruskan',
            'RegionManage': 'Rantau menguruskan',
            'RegionImagesManage': 'Mengurus imej rantau',
            'RepairLogManage': 'Pembaikan Pengurusan Log',
            'RoleManage': 'Peranan menguruskan',
            'TransactionLogManage': 'Pengurusan Log Transaksi',
            'UserManage': 'Pengurus Pengguna',
            'UserPlanManage': 'PlanManage Pengguna',
            'GetConfiguration': 'Dapatkan konfigurasi',
            'DataTransfer': 'Pemindahan data',

            'NoticeManage': 'Notis menguruskan',
            'PublicityManage': 'Pengurusan pengumuman',

            'ServiceManage': 'Urus Perkhidmatan',
            'CardManage': 'Pengurusan nombor kad',

            'ChargerSetting': 'Tetapan pengecas',
            'StatisticsManage': 'Statistik',

            'AppManagement': 'Pengurusan App',
            'LogManagement': 'Pengurusan log',
            'AppVersionManage': 'Versi aplikasi menguruskan',
            'WallerManage': 'Had akaun',
            'iPadManagement': 'Pengurusan Rata',
            'iPadManage': 'Rata Menguruskan',

            'TenantManagement': 'Pengurusan Sistem',
            'SuperTenantManage': 'Super Tenant',
            'TenantManage': 'Penyewa menguruskan',
            'TenantInfo': 'Penyewa menguruskan',
            'TenantPackage': 'Pakej Tenant',

            'HsbcManage': 'HSBC',
        },
        'table': {'order_id': 'ID pesanan', 'url': 'Url'},
        'field': {
            'pid': 'Pid',
            'order_id': 'ID pesanan',
            'name': 'Nama',
            'url': 'Url',
            'status': 'Negeri',
            'icon': 'Ikon'
        }
    },
    'login': {
        'validate': {
            'required': {
                'username': 'Sila masukkan nama pengguna',
                'email': 'Sila masukkan e -mel',
                'password': 'Sila masukkan kata laluan',
                'captcha': 'Sila masukkan Captcha'
            }
        }
    },
    'authority': {
        'name': {'title': 'Kuasa', 'detailTitle': 'Perincian kuasa', 'editTitle': 'Edit kuasa'},
        'table': {
            'id_aut': 'ID',
            'a_id_aut': 'Pihak Berkuasa Ibu Bapa',
            'name': 'Kuasa',
            'resource_name': 'Nama sumber',
            'type': 'Jenis',
            'sort': 'Menyusun'
        },
        'field': {
            'id_aut': 'ID',
            'a_id_aut': 'Pihak Berkuasa Ibu Bapa',
            'name': 'Kuasa',
            'resource_name': 'Nama sumber',
            'type': 'Jenis',
            'sort': 'Menyusun'
        },
        'validate': {
            'required': {
                'id_aut': 'ID diperlukan',
                'a_id_aut': 'Pihak berkuasa induk diperlukan',
                'name': 'Pihak berkuasa diperlukan',
                'resource_name': 'Nama sumber yang diperlukan',
                'type': 'Jenis yang diperlukan',
                'sort': 'Jenis yang diperlukan'
            }
        }
    },
    'balanceLog': {
        'rechargePackage': 'Muat semula Pakej',
        'addRule': 'Tambah Peraturan',
        'gift': {
            'enable': 'Adakah ia dibenarkan',
            'percentage': 'Mengikut peratus',
            'star': 'Nilai permulaan',
            'end': 'Nilai akhir',
            'give': 'Had',
        },
        'vGift': {
            'enable': 'Sila pilih sama ada hendak benarkannya atau tidak',
            'percentage': 'Sila pilih sama ada hendak guna peratus atau tidak',
            'star': 'Sila masukkan nilai permulaan',
            'end': 'Sila masukkan nilai akhir',
            'give': 'Sila masukkan had kredit',
        },
        'name': {
            'title': 'Log keseimbangan',
            'detailTitle': 'Perincian log keseimbangan',
            'editTitle': 'Edit Log Baki',
            'ticket': 'Nota hukuman',
            'cancelFines': 'Batal denda'
        },
        'table': {
            'id_bal_log': 'ID',
            'id_use': 'Pengguna',
            'type': 'Jenis',
            'amount': 'Jumlah',
            'log_time': 'Masa log',
            'tenant_key': 'Kunci Penyewa',
            'status': 'Status',
            'payment_method': 'Kaedah pembayaran'
        },
        'field': {
            'id_bal_log': 'ID',
            'id_use': 'Pengguna',
            'type': 'Jenis',
            'amount': 'Jumlah',
            'log_time': 'Masa log',
            'tenant_key': 'Kunci Penyewa',
            'status': 'Status',
            'payment_method': 'Kaedah pembayaran'
        },
        'validate': {
            'required': {
                'id_bal_log': 'ID diperlukan',
                'id_use': 'Pengguna diperlukan',
                'type': 'Jenis yang diperlukan',
                'amount': 'Jumlah yang diperlukan',
                'log_time': 'Masa log diperlukan',
                'tenant_key': 'Kunci penyewa diperlukan',
                'status': 'Status diperlukan',
                'payment_method': 'Kaedah pembayaran diperlukan'
            }
        }
    },
    'car': {
        'name': {'title': 'Kereta', 'detailTitle': 'Perincian kereta', 'editTitle': 'Edit kereta'},
        'table': {
            'id_car': 'ID',
            'id_use': 'Pengguna',
            'model': 'Model',
            'car_license': 'Lesen',
            'max_years_old': 'Maksimum tahun',
            'manufacture_year': 'Tahun pembuatan',
            'maximum_power': 'Kuasa maksimum(kw)',
            'is_second_hand': 'Adalah tangan kedua',
            'vehicle_inspection_date': 'Tarikh pemeriksaan kenderaan',
            'insurance_expiry_date': 'Tarikh luput insurans',
            'tenant_key': 'Kunci Penyewa'
        },
        'field': {
            'id_car': 'ID',
            'id_use': 'Pengguna',
            'model': 'Model',
            'car_license': 'Lesen',
            'max_years_old': 'Maksimum tahun',
            'manufacture_year': 'Tahun pembuatan',
            'maximum_power': 'Kuasa maksimum(kw)',
            'is_second_hand': 'Adalah tangan kedua',
            'vehicle_inspection_date': 'Tarikh pemeriksaan kenderaan',
            'insurance_expiry_date': 'Tarikh luput insurans',
            'tenant_key': 'Kunci Penyewa'
        },
        'validate': {
            'required': {
                'id_car': 'ID diperlukan',
                'id_use': 'Pengguna diperlukan',
                'model': 'Model diperlukan',
                'car_license': 'Lesen diperlukan',
                'max_years_old': 'Tahun maksimum diperlukan',
                'manufacture_year': 'Tahun pembuatan diperlukan',
                'maximum_power': 'Kuasa maksimum diperlukan(kw)',
                'is_second_hand': 'Adakah tangan kedua diperlukan',
                'vehicle_inspection_date': 'Tarikh Pemeriksaan Kenderaan Diperlukan',
                'insurance_expiry_date': 'Tarikh luput insurans diperlukan',
                'tenant_key': 'Kunci penyewa diperlukan'
            }
        }
    },
    'carImages': {
        'name': {
            'title': 'Imej kereta',
            'detailTitle': 'Perincian imej kereta',
            'editTitle': 'Edit gambar kereta'
        },
        'table': {
            'id_car_ima': 'ID',
            'id_car': 'Kereta',
            'url': 'Url',
            'upload_time': 'Muat naik masa',
            'tenant_key': 'Kunci Penyewa'
        },
        'field': {
            'id_car_ima': 'ID',
            'id_car': 'Kereta',
            'url': 'Url',
            'upload_time': 'Muat naik masa',
            'tenant_key': 'Kunci Penyewa'
        },
        'validate': {
            'required': {
                'id_car_ima': 'ID diperlukan',
                'id_car': 'Kereta diperlukan',
                'url': 'URL diperlukan',
                'upload_time': 'Masa muat naik diperlukan',
                'tenant_key': 'Kunci penyewa diperlukan'
            }
        }
    },
    'chargeStationImages': {
        'name': {
            'title': 'Imej titik caj',
            'detailTitle': 'Perincian gambar titik caj',
            'editTitle': 'Edit gambar titik caj'
        },
        'table': {
            'id_ima': 'ID',
            'id_cha_poi': 'Titik caj',
            'url': 'Url',
            'upload_time': 'Muat naik masa',
            'tenant_key': 'Kunci Penyewa'
        },
        'field': {
            'id_ima': 'ID',
            'id_cha_poi': 'Titik caj',
            'url': 'Url',
            'upload_time': 'Muat naik masa',
            'tenant_key': 'Kunci Penyewa'
        },
        'validate': {
            'required': {
                'id_ima': 'ID diperlukan',
                'id_cha_poi': 'Titik caj diperlukan',
                'url': 'URL diperlukan',
                'upload_time': 'Masa muat naik diperlukan',
                'tenant_key': 'Kunci penyewa diperlukan'
            }
        }
    },
    'chargingStation': {
        'tooltip': {
            'postpaid': "Tetapkan urutan pembayaran perintah muatan, lalai ke tetapan kawasan",
            'billingMethod': "Tetapkan kaedah pemilihan perintah muatan, lalai untuk ditetapkan mengikut kawasan",
        },
        'billingMethodType': {
            '0':"Setelah masa",
            '1':"Mengikut Kuasa",
            '2':"Menurut Rancangan Pengguna",
            '3':"Tekan APP",
            '4':"Mengikut kawasan",
        },
        'updateFirmware': {
            'identifier': "Nombor siri",
            'location': "Alamat jauh",
            'retrieveDate': "Tarikh dapatan semula",
        },
        'uValidate': {
            'identifier': "Sila masukkan nombor siri",
            'location': "Sila masukkan alamat jauh",
            'retrieveDate': "Sila pilih tarikh carian",
        },
        'setting': {
            'index': "Nombor siri",
            'readonly': "Baca sahaja",
            'key': "Mengesetkan kekunci",
            'value': "Mengesetkan nilai",
        },
        'settingField': {
            'readonly': "Pilih sama ada ia adalah baca sahaja",
            'key': "Kekunci set tidak boleh kosong",
            'value': "Nilai seting tidak boleh kosong",
        },
        'name': {
            'title': 'Titik caj',
            'detailTitle': 'Perincian titik caj',
            'editTitle': 'Edit titik caj',
            'quickAdd': 'Tambah titik caj'
        },
        'table': {
            'id_cha_poi': 'ID',
            'id_met': 'Meter',
            'charge_box_id': 'ID kotak caj',
            'firmwareVersion': 'Versi perisian tegar',
            'last_heartbeat_time': 'Masa denyutan jantung terakhir',
            'monthly_fee': 'Yuran bulanan',
            'charger_type': 'Jenis pengecas',
            'tenant_key': 'Kunci Penyewa',
            'name': 'Nama',
            'heartbeat_interval': 'Selang jantung',
            'enable': 'Membolehkan',
            'maxCurrent': "Arus maksimum",
            'minCurrent': "Arus minimum",
            'isPrivate': "Peribadi atau tidak",
            'isReadMeter': "Sama ada hendak baca jadual",
            'isReset': "Sama ada memulakan semula (memulakan semula boleh mengambil kesan)",
            'action': {
                'updateFirmware': "Peningkatan perisian tegar",
                'hardReset': "Tetap Semula Sukar",
                'softReset': "Tetap Semula Lembut"
            }
        },
        'validate': {
            'id_cha_poi': 'ID diperlukan',
            'id_met': 'Meter diperlukan',
            'charge_box_id': 'ID kotak caj diperlukan',
            'last_heartbeat_time': 'Masa denyutan jantung terakhir diperlukan',
            'monthly_fee': 'Yuran bulanan diperlukan',
            'charger_type': 'Jenis pengecas diperlukan',
            'tenant_key': 'Kunci penyewa diperlukan',
            'name': 'Nama (diperlukan',
            'heartbeat_interval': 'Selang jantung diperlukan',
            'enable': 'Membolehkan diperlukan',
            'maxCurrent': "Sila isikan arus maksimum",
            'minCurrent': "Sila isikan semasa minimum",
        }
    },
    'connector': {
        'name': {
            'title': 'Penyambung',
            'detailTitle': 'Perincian penyambung',
            'editTitle': 'Edit penyambung',
            'startTransaction': 'Mulakan transaksi',
            'stopTransaction': 'Berhenti transaksi'
        },
        'table': {
            'id_con': 'ID',
            'id_cha_poi': 'Titik caj',
            'name': 'Nama',
            'connector_id': 'ID Penyambung',
            'status': 'Status',
            'tenant_key': 'Kunci Penyewa',
            'power': 'Kuasa(kw)',
            'type': 'Jenis'
        },
        'field': {
            'id_con': 'ID',
            'id_cha_poi': 'Titik caj',
            'name': 'Nama',
            'connector_id': 'ID Penyambung',
            'status': 'Status',
            'tenant_key': 'Kunci Penyewa',
            'power': 'Kuasa(kw)',
            'type': 'Jenis'
        },
        'validate': {
            'required': {
                'id_con': 'ID diperlukan',
                'id_cha_poi': 'Titik caj diperlukan',
                'name': 'Nama (diperlukan',
                'connector_id': 'ID penyambung diperlukan',
                'status': 'Status diperlukan',
                'tenant_key': 'Kunci penyewa diperlukan',
                'power': 'Kuasa diperlukan(kw)',
                'type': 'Jenis yang diperlukan'
            }
        }
    },
    'employee': {
        'name': {'title': 'Pengendali', 'detailTitle': 'Perincian pengendali', 'editTitle': 'Edit Operator'},
        'table': {
            'id_emp': 'ID',
            'id_rol': 'Peranan',
            'account': 'Akaun',
            'password': 'Kata laluan',
            'salt': 'Garam',
            'last_login_time': 'Masa log masuk terakhir',
            'register_time': 'Waktu Daftar',
            'login_failure_counter': 'Kaunter kegagalan log masuk',
            'email': 'E -mel',
            'whatsAppPhone': 'WhatsApp Nombor',
            'remark': 'Catatan',
            'status': 'Status',
            'name': 'Nama',
            'commission_rate': 'Kadar komisen',
            'yedpay_api_key': 'Kunci API Yedpay',
            'yedpay_sign_key': 'Kunci tanda Yedpay',
            'tenant_key': 'Kunci Penyewa'
        },
        'field': {
            'id_emp': 'ID',
            'id_rol': 'Peranan',
            'account': 'Akaun',
            'password': 'Kata laluan',
            'salt': 'Garam',
            'last_login_time': 'Masa log masuk terakhir',
            'register_time': 'Waktu Daftar',
            'login_failure_counter': 'Kaunter kegagalan log masuk',
            'email': 'E -mel',
            'whatsAppPhone': 'WhatsApp Nombor',
            'remark': 'Catatan',
            'status': 'Status',
            'name': 'Nama',
            'commission_rate': 'Kadar komisen',
            'yedpay_api_key': 'Kunci API Yedpay',
            'yedpay_sign_key': 'Kunci tanda Yedpay',
            'tenant_key': 'Kunci Penyewa'
        },
        'validate': {
            'required': {
                'id_emp': 'ID diperlukan',
                'id_rol': 'Peranan diperlukan',
                'account': 'Akaun diperlukan',
                'password': 'Kata laluan diperlukan',
                'salt': 'Garam diperlukan',
                'last_login_time': 'Masa log masuk terakhir diperlukan',
                'register_time': 'Masa mendaftar diperlukan',
                'login_failure_counter': 'Kaunter kegagalan log masuk diperlukan',
                'email': 'E -mel diperlukan',
                'whatsAppPhone': 'Sila isikan Nombor WhatsApp',
                'remark': 'Pernyataan yang diperlukan',
                'status': 'Status diperlukan',
                'name': 'Nama (diperlukan',
                'commission_rate': 'Kadar komisen diperlukan',
                'yedpay_api_key': 'Kunci API Yedpay diperlukan',
                'yedpay_sign_key': 'Kunci tanda Yedpay diperlukan',
                'tenant_key': 'Kunci penyewa diperlukan'
            }
        }
    },
    'eventLog': {
        'name': {
            'title': 'Log acara',
            'detailTitle': 'Perincian log peristiwa',
            'editTitle': 'Edit log acara'
        },
        'table': {
            'id_env_log': 'ID',
            'run_time': 'Masa Pelaksanaan(ms)',
            'class_method': 'Kaedah Laksanakan',
            'event_time': 'Masa acara',
            'log': 'Log',
            'tenant_key': 'Kunci Penyewa',
            'resCode': 'Kod balas',
        },
        'field': {'id_env_log': 'ID', 'event_time': 'Masa acara', 'log': 'Log', 'tenant_key': 'Kunci Penyewa'},
        'validate': {
            'required': {
                'id_env_log': 'ID diperlukan',
                'class_method': 'Sila isi kaedah pelaksanaan',
                'event_time': 'Masa acara diperlukan',
                'log': 'Log diperlukan',
                'tenant_key': 'Kunci penyewa diperlukan'
            }
        }
    },
    'meter': {
        'name': {'title': 'Meter', 'detailTitle': 'Perincian meter', 'editTitle': 'Edit meter'},
        'table': {
            'id_met': 'ID',
            'id_reg': 'Wilayah',
            'name': 'Nama meter',
            'current_threshold': 'Ambang semasa',
            'tenant_key': 'Kunci Penyewa'
        },
        'field': {
            'id_met': 'ID',
            'id_reg': 'Wilayah',
            'name': 'Nama meter',
            'current_threshold': 'Ambang semasa',
            'tenant_key': 'Kunci Penyewa',
            'loadByTactics': 'Menghidupkan Dasar'
        },
        'validate': {
            'required': {
                'id_met': 'ID diperlukan',
                'id_reg': 'Wilayah diperlukan',
                'name': 'Nama meter diperlukan',
                'current_threshold': 'Ambang semasa diperlukan',
                'tenant_key': 'Kunci penyewa diperlukan'
            }
        }
    },
    'order': {
        'name': {
            'title': 'Pesanan',
            'detailTitle': 'Perincian pesanan',
            'editTitle': 'Edit pesanan',
            'cancel': 'Batalkan Arahan',
            'cancelAndRefund': 'Batalkan perintah dan kembalikan',
        },
        'table': {
            'id_ord': 'ID',
            'id_car': 'Kereta',
            'id_con': 'Penyambung',
            'order_time': 'Masa pesanan',
            'type': 'Jenis',
            'amount': 'Jumlah',
            'charge_start_time': 'Mulakan masa muatan',
            'charge_end_time': 'Masa akhir dijangka',
            'chargeEndTimeActual': 'Masa akhir sebenar',
            'status': 'Status',
            'payment_time': 'Masa pembayaran',
            'electricity': 'Beli elektrik',
            'charge_minutes': 'Waktu pembelian',
            'currentElectricity': "Elektrik Semasa",
            'ref_id_pla': 'Merancang',
            'price': 'Harga',
            'commission_rate': 'Kadar komisen',
            'tenant_key': 'Kunci Penyewa',
            'id_tra_log': 'TransactionLogid',

            'pay_type': 'PayType',
            'payment_method': 'Kaedah pembayaran'
        },
        'validate': {
            'id_ord': 'ID diperlukan',
            'id_car': 'Kereta diperlukan',
            'id_con': 'Penyambung diperlukan',
            'order_time': 'Masa pesanan diperlukan',
            'type': 'Jenis yang diperlukan',
            'amount': 'Jumlah yang diperlukan',
            'charge_start_time': 'Masa Mula Caj diperlukan',
            'charge_end_time': 'Caj masa akhir yang diperlukan',
            'status': 'Status diperlukan',
            'payment_time': 'Masa pembayaran diperlukan',
            'electricity': 'Elektrik diperlukan',
            'ref_id_pla': 'Rancangan diperlukan',
            'price': 'Harga diperlukan',
            'commission_rate': 'Kadar komisen diperlukan',
            'tenant_key': 'Kunci penyewa diperlukan',
            'id_tra_log': 'TransactionLogID diperlukan',
            'charge_minutes': 'Chargeminutes diperlukan',
            'pay_type': 'PayType diperlukan',
            'payment_method': 'Kaedah pembayaran diperlukan'
        }
    },
    'plan': {
        'name': {'title': 'Merancang', 'detailTitle': 'Rancang perincian', 'editTitle': 'Edit rancangan'},
        'table': {
            'id_pla': 'ID',
            'name': 'Rancangan Nama',
            'price_per_month': 'Harga',
            'contract_period': 'Tempoh kontrak',
            'free_charging_time_limit': 'Masa pengecasan percuma',
            'overtime_charge_price': 'Harga lebih masa',
            'tenant_key': 'Kunci Penyewa'
        },
        'field': {
            'id_pla': 'ID',
            'name': 'Rancangan Nama',
            'price_per_month': 'Harga',
            'contract_period': 'Tempoh kontrak',
            'free_charging_time_limit': 'Masa pengecasan percuma',
            'overtime_charge_price': 'Harga lebih masa',
            'tenant_key': 'Kunci Penyewa'
        },
        'validate': {
            'required': {
                'id_pla': 'ID diperlukan',
                'name': 'Rancangan Nama Diperlukan',
                'price_per_month': 'Harga diperlukan',
                'contract_period': 'Tempoh kontrak diperlukan',
                'free_charging_time_limit': 'Masa pengecasan percuma diperlukan',
                'overtime_charge_price': 'Harga lebih masa diperlukan',
                'tenant_key': 'Kunci penyewa diperlukan'
            }
        }
    },
    'region': {
        'tooltip': {
            'byPowerUnit': "Saiz langkah setiap lompat dihitung sebagai satu lompat jika ia kurang dari satu lompat. Jika ia dihitung berdasarkan konsumsi elektrik sebenar tertib, sila tetapkannya kepada sifar.",
            'byTimeUnit': "Saiz langkah setiap lompat dihitung sebagai satu lompat jika ia kurang dari satu lompat. Jika ia dihitung berdasarkan masa sebenar tertib, sila tetapkannya kepada sifar.",

            'maximumPower': "Bila memuatkan mengikut aras bateri, had atas muatan tunggal tidak terbatas jika kiri kosong atau sifar, dan tidak terbatas secara lalai",
            'maximumMinute': "Bila memuatkan mengikut masa, had atas masa memuatkan tunggal ditinggalkan kosong atau sifar, yang tidak terbatas secara lalai",
        },
        'billingMethodType':{
            '0': "Setelah masa",
            '1': "Mengikut Kuasa",
            '2': "Tekan APP",
        },
        'name': {'title': 'Wilayah', 'detailTitle': 'Perincian rantau', 'editTitle': 'Edit rantau'},
        'table': {
            'id_reg': 'ID',
            'name': 'Nama Wilayah',
            'ac_price_per_electricity': 'Harga AC setiap elektrik',
            'ac_price_per_minute': 'Harga AC seminit',
            'lon': 'Lon',
            'lat': 'Lat',
            'address': 'Alamat',
            'dc_price_per_electricity': 'Harga DC setiap elektrik',
            'dc_price_per_minute': 'Harga DC seminit',
            'tenant_key': 'Kunci Penyewa',
            'businessAllDay': 'Buka sepanjang hari',
            'images': 'Imej',
            'byPowerUnit': "Unit pengukuran",
            'byTimeUnit': "Menurut masa Unit pengukuran",
            'postpaid': "Postpaid",
            'billingMethod': "Kaedah Billing",
            'businessHours': "Jam perniagaan",

            'maximumPower': "Had atas kapasitas muatan tunggal",
            'maximumMinute': "Had atas masa muatan tunggal",
            'directDebit': "Hapusan automatik",

            'enableTimeoutFine': "Buka hukuman tamat masa",
            'timeoutFine': "Masa tamat masa(min)",
            'timeoutCharging': "Biling Overtime",
        },
        'validate': {
            'id_reg': 'ID diperlukan',
            'name': 'Nama Wilayah diperlukan',
            'ac_price_per_electricity': 'Harga AC setiap elektrik diperlukan',
            'ac_price_per_minute': 'Harga AC seminit diperlukan',
            'lon': 'Lon diperlukan',
            'lat': 'LAT diperlukan',
            'address': 'Alamat diperlukan',
            'dc_price_per_electricity': 'Harga DC setiap elektrik diperlukan',
            'dc_price_per_minute': 'Harga DC seminit diperlukan',
            'tenant_key': 'Kunci penyewa diperlukan',
            'businessAllDay': 'Sila pilih sama ada ia dibuka sepanjang hari',
            'byPowerUnit': "Sila isi Unit pengukuran berdasarkan kuantiti elektrik",
            'byTimeUnit': "Sila isi Unit pengukuran mengikut masa",
            'postpaid': "Sila pilih sama ada perlu membayar selepas",
            'billingMethod': "Sila pilih kaedah pemilihan",
            'businessHours': "Pilih jam perniagaan",

            'maximumPower': "Sila isi had atas kapasitas muatan tunggal",
            'maximumMinute': "Sila isi had atas masa muatan tunggal",
            'directDebit': "Sila pilih sama ada hendak menurunkan pembayaran secara automatik",

            'enableTimeoutFine': "Sila pilih sama ada hendak benarkan hukuman tamat masa",
            'timeoutFine': "Sila isi masa tamat(min)",
            'timeoutCharging': "Sila isi bil jam tambahan",
        }
    },
    'regionImages': {
        'name': {
            'title': 'Imej Wilayah',
            'detailTitle': 'Perincian imej wilayah',
            'editTitle': 'Edit Imej Wilayah'
        },
        'table': {
            'id_reg_img': 'ID',
            'id_reg': 'Wilayah',
            'url': 'Url',
            'upload_time': 'Muat naik masa',
            'tenant_key': 'Kunci Penyewa'
        },
        'field': {
            'id_reg_img': 'ID',
            'id_reg': 'Wilayah',
            'url': 'Url',
            'upload_time': 'Muat naik masa',
            'tenant_key': 'Kunci Penyewa'
        },
        'validate': {
            'required': {
                'id_reg_img': 'ID diperlukan',
                'id_reg': 'Wilayah diperlukan',
                'url': 'URL diperlukan',
                'upload_time': 'Masa muat naik diperlukan',
                'tenant_key': 'Kunci penyewa diperlukan'
            }
        }
    },
    'repairLog': {
        'name': {
            'title': 'Log pembaikan',
            'detailTitle': 'Perincian log pembaikan',
            'editTitle': 'Edit log pembaikan'
        },
        'table': {
            'id_rep_log': 'ID',
            'id_car': 'Kereta',
            'amount': 'Jumlah',
            'repair_date': 'Tarikh pembaikan',
            'remark': 'Catatan',
            'tenant_key': 'Kunci Penyewa'
        },
        'field': {
            'id_rep_log': 'ID',
            'id_car': 'Kereta',
            'amount': 'Jumlah',
            'repair_date': 'Tarikh pembaikan',
            'remark': 'Catatan',
            'tenant_key': 'Kunci Penyewa'
        },
        'validate': {
            'required': {
                'id_rep_log': 'ID diperlukan',
                'id_car': 'Kereta diperlukan',
                'amount': 'Jumlah yang diperlukan',
                'repair_date': 'Tarikh pembaikan diperlukan',
                'remark': 'Pernyataan yang diperlukan',
                'tenant_key': 'Kunci penyewa diperlukan'
            }
        }
    },
    'role': {
        'name': {'title': 'Peranan', 'detailTitle': 'Perincian peranan', 'editTitle': 'Edit peranan'},
        'table': {'id_rol': 'ID', 'name': 'Peranan', 'remark': 'Catatan'},
        'field': {'id_rol': 'ID', 'name': 'Peranan', 'remark': 'Catatan', 'authorityList': 'Senarai Pihak Berkuasa'},
        'validate': {
            'required': {
                'id_rol': 'ID diperlukan',
                'name': 'Peranan diperlukan',
                'remark': 'Pernyataan yang diperlukan'
            }
        }
    },
    'roleAuthority': {
        'name': {
            'title': 'RoleAuthority',
            'detailTitle': 'Perincian RoleAuthority',
            'editTitle': 'Edit RoleAuthority'
        },
        'table': {'id_rol_aut': 'ID', 'id_rol': 'Peranan', 'id_aut': 'Kuasa'},
        'field': {'id_rol_aut': 'ID', 'id_rol': 'Peranan', 'id_aut': 'Kuasa'},
        'validate': {
            'required': {
                'id_rol_aut': 'ID diperlukan',
                'id_rol': 'Peranan diperlukan',
                'id_aut': 'Pihak berkuasa diperlukan'
            }
        }
    },
    'transactionLog': {
        'name': {'title': 'Log transaksi', 'detailTitle': 'Perincian log transaksi', 'editTitle': 'Edit log transaksi'},
        'table': {
            'id_tra_log': 'ID',
            'id_con': 'Penyambung IDT',
            'event_time': 'Masa acara',
            'id_tag': 'Tag ID',
            'start_time': 'Masa mula',
            'start_value': 'Nilai Mula',
            'stop_time': 'Berhenti masa',
            'stop_value': 'Nilai berhenti',
            'stop_reason': 'Berhenti alasan',
            'fail_reason': 'Gagal alasan',
            'tenant_key': 'Kunci Penyewa',
            'current_electricity': 'Elektrik semasa'
        },
        'field': {
            'id_tra_log': 'ID',
            'id_con': 'Penyambung IDT',
            'event_time': 'Masa acara',
            'id_tag': 'Tag ID',
            'start_time': 'Masa mula',
            'start_value': 'Nilai Mula',
            'stop_time': 'Berhenti masa',
            'stop_value': 'Nilai berhenti',
            'stop_reason': 'Berhenti alasan',
            'fail_reason': 'Gagal alasan',
            'tenant_key': 'Kunci Penyewa',
            'current_electricity': 'Elektrik semasa'
        },
        'validate': {
            'required': {
                'id_tra_log': 'ID diperlukan',
                'id_con': 'Penyambung IDT diperlukan',
                'event_time': 'Masa acara diperlukan',
                'id_tag': 'Tag ID diperlukan',
                'start_time': 'Masa mula diperlukan',
                'start_value': 'Nilai mula diperlukan',
                'stop_time': 'Masa berhenti diperlukan',
                'stop_value': 'Nilai berhenti diperlukan',
                'stop_reason': 'Alasan berhenti diperlukan',
                'fail_reason': 'Alasan gagal diperlukan',
                'tenant_key': 'Kunci penyewa diperlukan',
                'current_electricity': 'Elektrik semasa diperlukan'
            }
        }
    },
    'user': {
        'name': {'title': 'Pengguna', 'detailTitle': 'Perincian pengguna', 'editTitle': 'Edit pengguna'},
        'table': {
            'id_use': 'ID',
            'balance': 'Seimbang',
            'account': 'Akaun',
            'password': 'Kata laluan',
            'register_time': 'Waktu Daftar',
            'email': 'E -mel',
            'token': 'Token',
            'salt': 'Garam',
            'tenant_key': 'Kunci Penyewa',
            'phone': 'Telefon',
            'register_type': 'Jenis Daftar'
        },
        'field': {
            'id_use': 'ID',
            'balance': 'Seimbang',
            'account': 'Akaun',
            'password': 'Kata laluan',
            'register_time': 'Waktu Daftar',
            'email': 'E -mel',
            'token': 'Token',
            'salt': 'Garam',
            'tenant_key': 'Kunci Penyewa',
            'phone': 'Telefon',
            'register_type': 'Jenis Daftar',

            'chargingCard': 'Memuatkan nombor kad',
            'chargingType': 'Mod muatan keutamaan',
            'chargingDefDuration': 'Masa muatan kad(min)',
            'chargingDefPower': 'Kapasiti muatan kad(kWh)'
        },
        'validate': {
            'required': {
                'id_use': 'ID diperlukan',
                'balance': 'Baki diperlukan',
                'account': 'Akaun diperlukan',
                'password': 'Kata laluan diperlukan',
                'register_time': 'Masa mendaftar diperlukan',
                'email': 'E -mel diperlukan',
                'token': 'Token diperlukan',
                'salt': 'Garam diperlukan',
                'tenant_key': 'Kunci penyewa diperlukan',
                'phone': 'Telefon diperlukan',
                'register_type': 'Jenis Daftar Diperlukan',

                'chargingCard': 'Sila isi nombor kad muatan',
                'chargingType': 'Sila pilih mod muatan keutamaan kad',
                'chargingDefDuration': 'Sila isi masa muatan kad(min)',
                'chargingDefPower': 'Sila isi jumlah muatan kad(kWh)',
            }
        }
    },
    'userPlan': {
        'name': {
            'title': 'Rancangan pengguna',
            'detailTitle': 'Perincian pelan pengguna',
            'editTitle': 'Edit pelan pengguna'
        },
        'table': {
            'id_use': 'Pengguna',
            'id_pla': 'Merancang',
            'arrearsCount': 'Kiraan',
            'start_date': 'Tarikh mula',
            'tenant_key': 'Kunci Penyewa',
            'createType': 'Cipta Identiti',
            'updateByMonth': 'Masa Pembayaran Lanjut',

            'total': 'Bulan yang dibayar dahulu',
            'amount': 'Jumlah bayaran dijangka',
            'prePayData': 'Bulan pembayaran dijangka',
        },
        'validate': {
            'id_use': 'Pengguna diperlukan',
            'id_pla': 'Rancangan diperlukan',
            'arrearsCount': 'Sila isi bilangan bulan tertinggal',
            'start_date': 'Tarikh mula diperlukan',
            'tenant_key': 'Kunci penyewa diperlukan'
        }
    },
    'dashboard': {
        'name': {
            'totalChargers': 'Jumlah Pengecas',
            'availableChargers': 'Bilangan pelabuhan yang ada',
            'inUseChargers': 'Bilangan port yang sedang digunakan',
            'unavailableChargers': 'Bilangan pelabuhan tidak tersedia',
            'portUsage': 'Penggunaan pelabuhan',

            'energyUsage': 'Penggunaan Tenaga (kWh)',
            'totalRevenue': 'Jumlah hasil',
            'totalEnergy': 'Jumlah tenaga',
            'newUser': 'Pengguna baru'
        }
    },
    'appVersionManage': {
        'field': {
            'androidVersion': 'Versi Android',
            'androidDownloadLink': 'Pautan muat turun Android',
            'androidComplieNumber': 'Nombor kompilasi Android',
            'iosVersion': 'Versi iOS',
            'iosBundleId': 'Id bundle iOS',
            'iosComplieNumber': 'Nombor penyusunan iOS',
            'isForcedUpdate': 'Anda ingin paksa kemaskini'
        },
        'validate': {
            'required': {
                'androidVersion': 'Versi Android diperlukan',
                'androidDownloadLink': 'Pautan muat turun Android diperlukan',
                'androidComplieNumber': 'Nombor kompil Android diperlukan',
                'iosVersion': 'Versi iOS diperlukan',
                'iosBundleId': 'ID bundle iOS diperlukan',
                'iosComplieNumber': 'Nombor kompilasi iOS diperlukan',
                'isForcedUpdate': 'Sila pilih sama ada paksa kemaskini'
            }
        }
    },
    'dataTransfer': {'field': {'messageId': 'Menetapkan kekunci', 'dataStr': 'Menetapkan nilai'}},
    'superTenantManage': {
        'name': {'title': 'Kebenaran', 'detailTitle': 'Perincian', 'editTitle': 'Edit'},
        'searchForm': {
            'dataSourceKey': 'Kunci sumber data',
            'url': 'Url',
            'username': 'Nama pengguna',
            'tenant': 'Penyewa',
            'isEnable': 'Didayakan',
            'isCreate': 'Mencipta'
        },
        'searchFormPlaceholder': {
            'dataSourceKey': 'Sila masukkan kekunci sumber data',
            'url': 'Sila masukkan alamat pautan',
            'username': 'Sila masukkan akaun',
            'tenant': 'Sila masukkan nama penyewa',
            'isEnable': 'Sila pilih sama ada untuk didayakan',
            'isCreate': 'Sila pilih untuk mencipta'
        },
        'table': {
            'index': 'Indeks',
            'dataSourceKey': 'Kunci sumber data',
            'url': 'Url',
            'host': 'Tuan rumah',
            'port': 'Pelabuhan',
            'dbName': 'Nama Pangkalan Data',
            'username': 'Nama pengguna',
            'password': 'Kata laluan',
            'tenant': 'penyewa',
            'isEnable': 'Didayakan',
            'isCreate': 'Mencipta',
            'domain': 'Domain',
            'action': {'testConn': 'Conn', 'create': 'Buat', 'edit': 'Edit', 'del': 'Padam'}
        },
        'field': {
            'id': 'ID',
            'datasourceKey': 'Kunci sumber data',
            'seckey': 'Kekunci capaian',
            'url': 'Url',
            'host': 'Tuan rumah',
            'port': 'Pelabuhan',
            'dbName': 'Nama Pangkalan Data',
            'username': 'Nama pengguna',
            'password': 'Kata laluan',
            'tenant': 'Penyewa',
            'isEnable': 'Didayakan',
            'isCreate': 'Mencipta',
            'remark': 'Catatan',
            'domain': 'Domain',
            'onesignalKey': 'Kekunci pemberitahuan APP',
        },
        'validate': {
            'required': {
                'id': 'Sila isi ID',
                'dataSourceKey': 'Sila isi Kunci Sumber Data',
                'seckey': 'Sila isi Kekunci capaian',
                'url': 'Sila isi URL pautan',
                'host': 'Sila isi tuan rumah',
                'port': 'Sila isi pelabuhan',
                'dbName': 'Sila isi nama pangkalan data',
                'username': 'Sila masukkan nama pengguna',
                'password': 'Sila isi kata laluan',
                'tenant': 'Sila isi nama penyewa',
                'isEnable': 'Sila pilih mencipta',
                'remark': 'Sila isi ucapan',
                'domain': 'Sila isi domain',
                'onesignalKey': 'Sila isikan kunci pemberitahuan APP',
            }
        }
    },
    'iPadManage': {
        'field': {
            'id': 'ID',
            'index': 'Indeks',
            'machineCode': 'Kod mesin',
            'name': 'Nama',
            'regId': 'ID Wilayah',
            'regName': 'Nama Wilayah',
            'seckey': 'Kekunci capaian',
            'args': 'Parameter lain',
            'createBy': 'Buat oleh',
            'createTime': 'Buat masa',
            'updateBy': 'Kemas kini oleh',
            'updateTime': 'Masa kemas kini',
            'remark': 'Catatan'
        },
        'validate': {
            'id': 'Sila isi ID',
            'machineCode': 'Sila isi kod mesin',
            'name': 'Sila isi nama',
            'regId': 'Sila isi ID Wilayah',
            'seckey': 'Sila isi Kekunci capaian',
            'args': 'Sila isi Parameter lain',
            'remark': 'Sila isi ucapan'
        }
    },
    'noticeManage': {
        'noticeName': {'0': 'E-mel', '1': 'Telefon'},
        'noticeType': {
            '0': 'Amaran',
            '1': 'Ingatkan',
            '2': 'Pengumuman',
            '3': 'Jam tambahan'
        },
        'field': {
            'id': 'ID',
            'index': 'Indeks',
            'toFrom': 'Akaun',
            'type': 'Jenis Akaun',
            'noticeType': 'Jenis pemberitahuan',
            'status': 'Diaktifkan',
            'createBy': 'Buat oleh',
            'createTime': 'Buat masa',
            'updateBy': 'Kemas kini oleh',
            'updateTime': 'Masa kemas kini',
            'remark': 'Catatan'
        },
        'validate': {
            'id': 'Sila isi ID',
            'type': 'Sila pilih Jenis Akaun',
            'toFrom': 'Sila isi akaun',
            'noticeType': 'Sila pilih Jenis Pemberitahuan',
            'status': 'Sila pilih sama ada untuk membolehkan',
            'remark': 'Sila isi ucapan'
        }
    },
    'publicityManage':{
        'publicityType': {
            '0': 'Syarat Perkhidmatan',
            '1': 'Tentang kami',
            '2': 'Pengumuman',
            '3': 'Notis',
            '4': 'Mengingatkan',
        },
        // 表单
        'field': {
            'id': 'ID',
            'index': 'serial number',
            'type': 'Jenis iklan',
            'status': 'Sama ada ia didayakan',
            'title': 'Tajuk pengumuman',
            'content': 'Pemberitahuan',
            'tenantKey': 'Identiti penyewa',

            'createBy': 'Dicipta oleh',
            'createTime': 'Masa penciptaan',
            'updateBy': 'Dikemaskini oleh',
            'updateTime': 'Masa kemas kini',
            'remark': 'Kenyataan',
        },
        // 表单校验
        'validate': {
            'id': 'Sila isi ID',
            'type': 'Pilih jenis pengumuman',
            'status': 'Sila pilih sama ada untuk mendayakannya',
            'title': 'Sila isikan tajuk pengumuman',
            'content': 'Sila isi kandungan notis',
            'remark': 'Sila isikan komen-komen',
        }
    },
    'statisticsManage': {
        'table': {
            'index': 'Nombor siri',
            'identifier': 'Pengecam longgokan',
            'useNum': 'Bilangan kegunaan',
            'chargeCapacity': 'Jumlah penggunaan(kw)',

            'startTime': 'Masa mula',
            'endTime': 'Masa tamat'
        },
        'validate': {
            'identifier': 'Sila masukkan pengecam longgokan',
            'startTime': 'Sila pilih masa mula',
            'endTime': 'Sila pilih masa tamat'
        }
    },
    'serviceManage': {
        'type': {
            '0': 'Minum',
            '1': 'Makanan',
            '2': 'Tandas',
            '3': 'Beli-belah',
        },
        'table': {
            'id': 'ID',
            'type': 'type',
            'status': 'status',
            'name': 'name',
            'lng': 'longitud',
            'lat': 'latitud',
            'address': 'alamat',
            'description': 'perihalan',
            'reId': 'ID kawasan berkaitan'
        },
        'validate': {
            'type': 'Sila pilih jenis',
            'status': 'Sila pilih status',
            'name': 'Sila isi nama',
            'lng': 'Sila isi longitud',
            'lat': 'Sila isi latitud',
            'address': 'Sila isikan alamat',
            'description': 'Sila isi keterangan',
            'reId': 'Kawasan berkaitan'
        }
    },
    'companyManage': {
        'field': {
            'id': 'Kekunci Utama',
            'tId': 'Associate Tenant ID',
            'name': 'Nama Syarikat',
            'attn': 'Company Liaison',
            'tel': 'Telefon Syarikat',
            'address': 'Alamat Syarikat',
            'email': 'E-mel Syarikat',
            'invoiceDate': 'Tarikh Fakta',
            'invoiceNo': 'Nombor Faktu',
            'contactPerson': 'Kenalan',
            'contactTel': 'Nombor kenalan',
            'contactEmail': 'Email kenalan',
            'monthlyFee': 'Nilai preset bayaran bulanan akses sistem',
            'company': 'Maklumat syarikat'
        },
        'validate': {
            'id': 'Sila masukkan kunci utama',
            'tId': 'Enter the associated tenant ID',
            'name': 'Sila masukkan nama syarikat',
            'attn': 'Sila masukkan orang kenalan syarikat',
            'tel': 'Sila masukkan telefon syarikat anda',
            'address': 'Sila masukkan alamat syarikat anda',
            'email': 'Sila masukkan e-mel syarikat anda',
            'invoiceDate': 'Sila masukkan tarikh faktur',
            'invoiceNo': 'Sila masukkan nombor faktur',
            'contactPerson': 'Sila masukkan orang kenalan',
            'contactTel': 'Sila masukkan nombor kenalan anda',
            'contactEmail': 'Sila masukkan e-mel kenalan anda',
            'monthlyFee': 'Sila masukkan nilai lalai bayaran akses sistem bulanan'
        }
    },
    'tenantInfo': {
        'table': {
            'id': 'ID',
            'pid': 'ID Tenant Ibu Bapa',
            'tenantKey': 'Pengenalan',
            'name': 'Nama Tenant',
            'domain': 'Nama domain ikat',
            'tpId': 'Pakej Associated',
            'authId': 'Keizinan berkaitan',
            'level': 'level',
            'contact': 'Kenalan',
            'email': 'E-mel Kenalan',
            'phone': 'Nombor kenalan',
            'startTime': 'Masa aktivasi',
            'expireTime': 'Masa tamat',
        },
        'args':{
            'yedPayExtendParams': 'Parameter YedPay',
            'yedPayApiKey': 'YedPay ApiKey',
            'yedPaySingKey': 'YedPay SingKey',

            'mPayExtendParams': 'Parameter MPay',
            'mPayPubKey': 'Kekunci Awam MPay',
            'mPayPriKey': 'Kekunci peribadi MPay',
        },
        'validate': {
            'id': 'Sila masukkan ID',
            'pid': 'Sila masukkan ID penyewa ibubapa',
            'tenantKey': 'Sila isi ID penyewa',
            'name': 'Sila isi nama penyewa',
            'domain': 'Sila isi nama domain terikat',
            'tpId': 'Sila pilih pakej berkaitan',
            'authId': 'Sila pilih keizinan yang berkaitan',
            'contact': 'Sila isi orang kenalan',
            'email': 'Sila isi e-mel kenalan',
            'phone': 'Sila isi nombor telefon kenalan',
            'startTime': 'Sila pilih masa permulaan',
            'expireTime': 'Sila pilih masa luput',

            'yedPayExtendParams': 'Sila masukkan parameter YedPay',
            'yedPayApiKey': 'Sila masukkan YedPay ApiKey',
            'yedPaySingKey': 'Sila masukkan YedPay SingKey',
            'mPayExtendParams': 'Sila masukkan parameter MPay',
            'mPayPubKey': 'Sila masukkan kunci awam MPay',
            'mPayPriKey': 'Sila masukkan kunci peribadi MPay',
        }
    },
    'tenantPackage': {
        'table': {
            'id': 'ID',
            'packageNo': 'Nombor pakej',
            'name': 'Nama Pakej',
            'createTenantMax': 'Bilangan penyewa yang boleh dicipta',
            'createUserMax': 'Bilangan pengguna yang boleh dicipta',
            'createAppMax': 'Bilangan aplikasi boleh didaftar',
            'expireLong': 'Waktu berkesan pakej',
            'tenantKey': 'Pengenalan',
        },
        'validate': {
            'id': 'Sila masukkan ID',
            'packageNo': 'Sila masukkan nombor pakej',
            'name': 'Sila masukkan nama pakej',
            'createTenantMax': 'Sila masukkan bilangan penyewa yang boleh dicipta',
            'createUserMax': 'Sila masukkan bilangan pengguna yang boleh dicipta',
            'createAppMax': 'Sila masukkan bilangan aplikasi boleh didaftar',
            'expireLong': 'Sila pilih tempoh yang berkesan bagi pakej',
            'tenantKey': 'Sila masukkan ID penyewa',
        }
    },
    'card': {
        'cardType': {
            '0': 'Whitelist',
            '1': "Blacklist",
            '2': 'Kad sistem',
            '3': 'Kad pengguna',
        },
        'table': {
            'cardId': 'ID',
            'cardNo': 'Nombor kad',
            'cardType': 'Jenis',
            'cardStatus': 'Adakah ia dibenarkan',
        },
        'validate': {
            'cardId': 'Sila isi ID',
            'cardNo': 'Sila isi nombor kad',
            'cardType': 'Sila pilih jenis',
            'cardStatus': 'Sila pilih status',
        }
    },
    'waller': {
        'wType': {
            '1': 'balance',
            '2': 'Jumlah hadiah',
            '3': 'integral'
        },
        'table': {
            'walletId': 'ID',
            'account': 'akaun',
            'type': 'Jenis',
            'status': 'Berhenti atau tidak',
            'balance': 'balance',
        },
        'validate': {
            'account': 'Sila isi akaun anda',
            'type': 'Sila pilih jenis',
            'status': 'Sila pilih status',
        }
    }
}
export default msMY