<template>
  <Modal
      id="UserEdit"
      width="50%"
      :open="visible"
      @cancel="visible = !visible"
      @ok="submit"
      :title="title"
      :destroyOnClose="true"
  >
    <Row align="middle" justify="center">
      <Col span="18" >
        <Row>
          <Col span="24">
            <Form
                ref="userForm"
                :model="form"
                layout="vertical"
                :rules="validateRules"
            >
              <Row justify="space-around">
                <Col span="10" v-if="actionType === 'edit'">
                  <FormItem :label="$t('user.field.balance')" name="balance">
                    <InputNumber
                        disabled
                        v-model:value="form.balance"
                        :placeholder="$t('user.validate.required.balance')"
                        :step="0.01"
                        style="width: 100%"
                    />
                  </FormItem>
                </Col>
                <Col span="10">
                  <FormItem :label="$t('user.field.account')" name="account">
                    <Input
                        :disabled="actionType === 'edit'"
                        :placeholder="$t('user.validate.required.account')"
                        v-model:value="form.account"
                    >
                    </Input>
                  </FormItem>
                </Col>
                <Col span="10">
                  <FormItem label="姓" name="firstName">
                    <Input
                        placeholder="請輸入姓氏"
                        v-model:value="form.firstName"
                    >
                    </Input>
                  </FormItem>
                </Col>
                <Col span="10">
                  <FormItem label="名" name="lastName">
                    <Input
                        placeholder="請輸入名稱"
                        v-model:value="form.lastName"
                    >
                    </Input>
                  </FormItem>
                </Col>

                <!-- <Col span="10">
                  <FormItem :label="$t('user.field.register_time')" name="registerTime">
                    <DatePicker show-time valueFormat="YYYY-MM-DD HH:mm:ss" v-model:value="form.registerTime" :popupStyle="{ zIndex: 9999999950 }"></DatePicker>
                </FormItem>
                </Col> -->

                <Col span="10">
                  <FormItem :label="$t('user.field.register_type')" name="registerType">
                    <Select
                        :disabled="actionType === 'edit'"
                        v-model:value="form.registerType"
                        :placeholder="$t('user.validate.required.register_type')"
                        :options="registerTypeList"
                        :dropdownStyle="{ zIndex: 9999999950 }"
                    >
                    </Select>
                  </FormItem>
                </Col>
                <Col span="10">
                  <FormItem
                      :label="$t('user.field.phone')"
                      name="phone"
                      :rules="[{
                        required: registerType === 2,
                        message: $t('user.validate.required.phone'),
                        trigger: 'change',
                        }]"
                  >
                    <Input :placeholder="$t('user.validate.required.phone')"
                           v-model:value="form.phone">
                    </Input>
                  </FormItem>
                </Col>
                <Col span="10">
                  <FormItem
                      :label="$t('user.field.email')"
                      name="email"
                      :rules="[{
                        required: registerType === 1,
                        message: $t('user.validate.required.email'),
                        trigger: 'change',
                        }]"
                  >
                    <Input :placeholder="$t('user.validate.required.email')"
                           v-model:value="form.email">
                    </Input>
                  </FormItem>
                </Col>

                <Col span="10">
                  <FormItem :label="$t('user.field.chargingCard')" name="chargingCard">
                    <Select
                        v-model:value="form.chargingCard"
                        show-search
                        :placeholder="$t('public.pleaseSelect')"
                        style="width: 100%"
                        :options="cardList"
                        :filter-option="filterOption"
                        @change="handleChange"
                        allowClear
                    ></Select>
                  </FormItem>
                </Col>
                <Col span="10">
                  <FormItem
                      :label="$t('user.field.chargingType')"
                      name="chargingType"
                      :rules="[{
                        required: requiredCard,
                        message: $t('user.validate.required.chargingType'),
                        trigger: 'change',
                        type: 'number',
                        }]"
                  >
                    <Select
                        v-model:value="form.chargingType"
                        :placeholder="$t('user.validate.required.chargingType')"
                        :options="typeList"
                        :dropdownStyle="{ zIndex: 9999999950 }"
                    >
                    </Select>
                  </FormItem>
                </Col>
                <Col span="10">
                  <FormItem
                      :label="$t('user.field.chargingDefDuration')"
                      name="chargingDefDuration"
                      :rules="[{
                        required: requiredCard,
                        message: $t('user.validate.required.chargingDefDuration'),
                        trigger: 'change',
                        type: 'number',
                        }]"
                  >
                    <InputNumber style="width: 100%" :placeholder="$t('user.validate.required.chargingDefDuration')" v-model:value="form.chargingDefDuration"></InputNumber>
                  </FormItem>
                </Col>
                <Col span="10">
                  <FormItem
                      :label="$t('user.field.chargingDefPower')"
                      name="chargingDefPower"
                      :rules="[{
                        required: requiredCard,
                        message: $t('user.validate.required.chargingDefPower'),
                        trigger: 'change',
                        type: 'number',
                        }]"
                  >
                    <InputNumber style="width: 100%" :placeholder="$t('user.validate.required.chargingDefPower')" v-model:value="form.chargingDefPower"></InputNumber>
                  </FormItem>
                </Col>
                <Col span="10">
                  <FormItem
                      label="審核狀態"
                      name="status"
                      :rules="{
                        required: true,
                        message: 'household type can not be null',
                        trigger: 'change',
                      }">
                    <Select v-model:value="form.status" :placeholder="$t('serviceManage.validate.type')" :dropdownStyle="{zIndex: 9999999950}" allowClear>
                      <SelectOption :value="0">待審核</SelectOption>
                      <SelectOption :value="1">已審核</SelectOption>
                      <SelectOption :value="2">凍結</SelectOption>
                    </Select>
                  </FormItem>
                </Col>
                <Col span="10" v-if="actionType === 'add'">
                  <FormItem :label="$t('user.field.password')" name="password">
                    <InputPassword :placeholder="$t('user.validate.required.password')"
                                   v-model:value="form.password">
                    </InputPassword>
                  </FormItem>
                </Col>

                <Col span="10">
                  <FormItem
                      label="住戶類型"
                      name="residentType"
                      :rules="{
                        required: true,
                        message: 'household type can not be null',
                        trigger: 'change',
                      }">
                    <Select v-model:value="form.residentType" :placeholder="$t('serviceManage.validate.type')" :dropdownStyle="{zIndex: 9999999950}" allowClear>
                      <SelectOption value="O">業主</SelectOption>
                      <SelectOption value="T">租客</SelectOption>
                    </Select>
                  </FormItem>
                </Col>

                <Col span="10">
                  <FormItem label="關聯父賬戶" name="subId">
                    <Select
                        v-model:value="form.subId"
                        show-search
                        :placeholder="$t('public.pleaseSelect')"
                        style="width: 100%"
                        :options="userList"
                        :filter-option="filterOption"
                        @change="handleChange"
                        allowClear
                    ></Select>
                  </FormItem>
                </Col>

                <Col span="10">
                  <FormItem
                      label="禁用充电"
                      name="disableChargeServer"
                      :rules="{
                        required: true,
                        message: 'can not be null',
                        trigger: 'change',
                      }">
                    <Select v-model:value="form.disableChargeServer" :placeholder="$t('serviceManage.validate.type')" :dropdownStyle="{zIndex: 9999999950}" allowClear>
                      <SelectOption :value="0">{{ $t('public.isTrue.0') }}</SelectOption>
                      <SelectOption :value="1">{{ $t('public.isTrue.1') }}</SelectOption>
                    </Select>
                  </FormItem>
                </Col>
                <Col span="10">
                  <FormItem
                      label="禁用月結"
                      name="disableStatement"
                      :rules="{
                        required: true,
                        message: 'can not be null',
                        trigger: 'change',
                      }">
                    <Select v-model:value="form.disableStatement" :placeholder="$t('serviceManage.validate.type')" :dropdownStyle="{zIndex: 9999999950}" allowClear>
                      <SelectOption :value="0">{{ $t('public.isTrue.0') }}</SelectOption>
                      <SelectOption :value="1">{{ $t('public.isTrue.1') }}</SelectOption>
                    </Select>
                  </FormItem>
                </Col>

                <Col span="22">
                  <FormItem :label="$t('public.name.address')" name="address">
                    <Textarea
                        :placeholder="$t('public.name.address')"
                        v-model:value="form.address">
                    </Textarea>
                  </FormItem>
                </Col>
                <Col span="22">
                  <FormItem :label="$t('public.name.remark')" name="remark">
                    <Textarea
                        :placeholder="$t('public.name.remark')"
                        v-model:value="form.remark">
                    </Textarea>
                  </FormItem>
                </Col>

                <Col span="10">
                  <FormItem
                      v-for="(item, index) in form.utils"
                      :key="item.key"
                      :label="index === 0 ? 'Unit' : ''"
                      :name="['utils', index, 'value']"
                      >
                    <Cascader v-model:value="item.value" :options="unitOptions" @change="changeUnitCascader" placeholder="Please select" />
                    <MinusCircleOutlined
                        v-if="form.utils.length > 1"
                        class="dynamic-delete-button"
                        @click="removeItem(item, 'unit')"
                    />
                  </FormItem>
                  <FormItem>
                    <Button type="dashed" style="width: 60%" @click="addItem('unit', '')">
                      <PlusOutlined />
                      Add Unit
                    </Button>
                  </FormItem>
                </Col>

                <Col span="10">
                  <FormItem
                      v-for="(item, index) in form.spaces"
                      :key="item.key"
                      :label="index === 0 ? 'Spaces' : ''"
                      :name="['spaces', index, 'value']"
                      >
                    <Cascader v-model:value="item.value" :options="spaceOptions"  @change="changeSpaceCascader" placeholder="Please select" />
                    <MinusCircleOutlined v-if="form.spaces.length > 1" class="dynamic-delete-button" @click="removeItem(item, 'space')" />
                  </FormItem>
                  <FormItem>
                    <Button type="dashed" style="width: 60%" @click="addItem('space', '')">
                      <PlusOutlined />
                      Add Spaces
                    </Button>
                  </FormItem>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Col>
    </Row>
  </Modal>
</template>
<script>
export default {
  name: "UserEdit",
}
</script>
<script setup>
import {
  Modal,
  Row,
  Col,
  Form,
  FormItem,
  Input,
  InputNumber,
  message,
  Select,
  SelectOption,
  Textarea,
  Cascader,
  Button,
  InputPassword
} from "ant-design-vue";
import { MinusCircleOutlined, PlusOutlined  } from "@ant-design/icons-vue";
import {UserAdd, UserEdit, UserListAll} from "@/api/user";
import {reactive, ref, toRefs, watch} from "vue";
import {useI18n} from "vue-i18n";
import {CardGetSelectList} from "@/api/card";
import {UnitGetTree, UnitGetUnitIdsByUserId, UnitInfoByUserId} from "@/api/unit";
import {SpaceGetSpaceIdsByUserId, SpaceGetTree, SpaceInfoByUserId} from "@/api/space";

const i18n = useI18n()
const emits = defineEmits(['updateList'])

let userForm = ref()

let visible = ref(false)
let title = ref("")
let actionType = ref("")
let cardList = ref([])
let userList = ref([])
let requiredCard = ref(false)
let registerType = ref()

const typeList = [{value: 1,label: i18n.t("public.chargeType.0")},{value: 2,label: i18n.t("public.chargeType.1")}]
const registerTypeList = [{value: 1, label: i18n.t("public.registerType.0")}, {value: 2, label: i18n.t("public.registerType.1")}]
const validateRules = ref({
  idUse: [
    {
      required: true,
      message: i18n.t("user.validate.required.id_use"),
      trigger: "change",
      type: "number",
    },
  ],
  balance: [
    {
      required: true,
      message: i18n.t("user.validate.required.balance"),
      trigger: "change",
      type: "number",
    },
  ],
  account: [
    {
      required: true,
      message: i18n.t("user.validate.required.account"),
      trigger: "change",
    },
  ],
  password: [
    {
      required: true,
      message: i18n.t("user.validate.required.password"),
      trigger: "change",
    },
  ],
  registerTime: [
    {
      required: true,
      message: i18n.t("user.validate.required.register_time"),
      trigger: "change",
    },
  ],
  // email: [
  //   {
  //     required: registerType.value === 1,
  //     message: i18n.t("user.validate.required.email"),
  //     trigger: "change",
  //     type: "email",
  //   },
  // ],
  token: [
    {
      required: true,
      message: i18n.t("user.validate.required.token"),
      trigger: "change",
    },
  ],
  salt: [
    {
      required: true,
      message: i18n.t("user.validate.required.salt"),
      trigger: "change",
    },
  ],
  tenantKey: [
    {
      required: true,
      message: i18n.t("user.validate.required.tenant_key"),
      trigger: "change",
    },
  ],
  // phone: [
  //   {
  //     required: registerType.value === 2,
  //     message: i18n.t("user.validate.required.phone"),
  //     trigger: "change",
  //   },
  // ],
  registerType: [
    {
      required: true,
      message: i18n.t("user.validate.required.register_type"),
      trigger: "change",
      type: "number",
    },
  ],
  // chargingCard: [
  //   {
  //     required: false,
  //     message: i18n.t("user.validate.required.chargingCard"),
  //     trigger: "change",
  //   },
  // ],
  // chargingType: [
  //   {
  //     required: false,
  //     message: i18n.t("user.validate.required.chargingType"),
  //     trigger: "change",
  //   },
  // ],
  // chargingDefDuration: [
  //   {
  //     required: false,
  //     message: i18n.t("user.validate.required.chargingDefDuration"),
  //     trigger: "change",
  //     type: "number",
  //   },
  // ],
  // chargingDefPower: [
  //   {
  //     required: false,
  //     message: i18n.t("user.validate.required.chargingDefPower"),
  //     trigger: "change",
  //     type: "number",
  //   },
  // ],
})

let unitOptions = ref([])
let spaceOptions = ref([])
const data = reactive({
  form: {
    utils: [],
    spaces: [],
  },
})
const {form} = toRefs(data)

watch(() => form.value.chargingCard, (newValue, oldValue) => {
  requiredCard.value = newValue !== null && newValue !== undefined
});

watch(() => form.value.registerType, (newValue, oldValue) => {
  console.log('Value changed:', newValue, oldValue);
  registerType.value = newValue
});

async function addShow() {
  title.value = i18n.t("public.name.add");
  actionType.value = "add";
  _resetData();
  init()
  await getCardList();
  visible.value = true;
}


async function editShow(obj) {
  actionType.value = "edit";
  title.value = i18n.t("public.name.edit");
  _resetData();
  init()
  await getCardList();
  form.value = JSON.parse(JSON.stringify(obj));
  form.value.chargingCard = form.value.chargingCard !== '' ? form.value.chargingCard : undefined
  registerType.value = form.value.registerType
  visible.value = true;
}

function submit() {
  userForm.value?.validate().then(() => {
    let api;
    switch (actionType.value) {
      case "add":
        api = new UserAdd();
        break;
      case "edit":
        api = new UserEdit();
        break;
      default:
        return false;
    }

    if (form.value.utils?.length > 0) {
      let uIds = []
      for (let i = 0; i < form.value.utils.length; i++) {
        const utils = form.value.utils[i].value
        let v = utils[utils.length - 1]
        if (!uIds.includes(v)) {
          uIds.push(v)
        }
      }
      form.value.unitIds = uIds
    }
    if (form.value.spaces?.length > 0) {
      let sIds = []
      for (let i = 0; i < form.value.spaces.length; i++) {
        const spaces = form.value.spaces[i].value
        let v = spaces[spaces.length - 1]
        if (!sIds.includes(v)) {
          sIds.push(v)
        }
      }
      form.value.spaceIds = sIds
    }
    api.post(form.value).then(response => {
      if (0 === parseInt(response.data.code)) {
        message.success(i18n.t("succeedMsg.0"));
        emits("updateList", response.data.data);
        visible.value = false;
      } else {
        if(response.data.code !== undefined){message.error(i18n.t("errorMsg." + response.data.code));}
      }
    })
  })
}

function _resetData() {
  form.value = {
    utils: [],
    spaces: [],
  };
}

async function getCardList() {
  // 获取卡号列表
  const api = new CardGetSelectList()
  api.get().then(response => {
    if (0 === parseInt(response.data.code)) {
      let lt = []
      const ls = response.data.data
      ls.forEach(item => {
        lt.push({
          label: item.cardNo,
          value: item.cardNo,
        })
      })
      cardList.value = lt
    } else {
      cardList.value = []
      if(response.data.code !== undefined){message.error(i18n.t("errorMsg." + response.data.code));}
    }
  });
}

function handleChange() {}

// 搜索框过滤筛选
function filterOption(input, option) {
  return option.label.indexOf(input) >= 0;
}

// 初始化
function init() {
  // 获取用户列表
  getUserList()
  // 获取单位树
  const uApi = new UnitGetTree()
  uApi.get().then(res => {
    unitOptions.value = res.data.data
    // 获取用户关联的单位ID
    if (actionType.value === "edit") {
      const guApi = new UnitInfoByUserId()
      guApi.get({userId:form.value.idUse}).then(res => {
        handleList("unit", res.data.data)
      })
    }
  })
  // 获取车位树
  const sApi = new SpaceGetTree()
  sApi.get().then(res => {
    spaceOptions.value = res.data.data
    // 获取用户关联的车位ID
    if (actionType.value === "edit") {
      const gsApi = new SpaceInfoByUserId()
      gsApi.get({userId:form.value.idUse}).then(res => {
        handleList("space" ,res.data.data)
      })
    }
  })
}
function handleList(t, list) {
  if (!list) {
    return
  }
  for (let i = 0; i < list.length; i++) {
    const vList = list[i].value
    if (!vList) {
      continue
    }
    let rL = []
    vList.forEach(item => {
      if (t === "unit") {
        rL.push(item.unitId)
      } else {
        rL.push(item.spaceId)
      }
    })
    addItem(t, rL)
  }
}

function addItem (t, v) {
  if (t === "unit") {
    if (!form.value.utils) {
      form.value.utils = []
    }
    form.value.utils.push({
      value: v,
      key: Date.now(),
    })
    return
  }
  if (!form.value.spaces) {
    form.value.spaces = []
  }
  form.value.spaces.push({
    value: v,
    key: Date.now(),
  })
}
function removeItem(item, t) {
  if (t === "unit") {
    const index = form.value.utils.indexOf(item);
    if (index !== -1) {
      form.value.utils.splice(index, 1);
    }
    return
  }
  const index = form.value.spaces.indexOf(item);
  if (index !== -1) {
    form.value.spaces.splice(index, 1);
  }
}

function changeUnitCascader(value, selectedOptions) {
}

function changeSpaceCascader(value, selectedOptions) {
}

function getUserList() {
  userList.value = []
  const api = new UserListAll();
  api.get().then((response) => {
    if (0 === parseInt(response.data.code)) {
      const data = response.data.data
      let l = []
      if (data) {
        for (let i = 0; i < data.length; i++) {
          const item = data[i]
          // 过滤掉子账号与当前账号
          if (item.idUse === form.value.idUse || form.value.subId) {
            continue
          }
          l.push({
            value: item.idUse,
            label: item.account
          })
        }
      }
      userList.value = l;
    } else {
      if (response.data.code !== undefined) {
        message.error(i18n.t("errorMsg." + response.data.code));
      }
    }
  });
}


defineExpose({
  addShow,
  editShow
})
</script>

<style lang="less">
.dynamic-delete-button {
  cursor: pointer;
  position: relative;
  top: 4px;
  font-size: 24px;
  color: #999;
  transition: all 0.3s;
}
.dynamic-delete-button:hover {
  color: #777;
}
.dynamic-delete-button[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}
</style>
