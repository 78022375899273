import {Base} from "@/api/Base"

const api = "work"

export class WorkAdd extends Base {
    path = api + "/add"
}

export class WorkDelete extends Base {
    path = api + "/delete"
}

export class WorkList extends Base {
    path = api + "/list"
}

export class WorkEdit extends Base {
    path = api + "/edit"
}

export class WorkExportToExcel extends Base {
    path = api + "/exportToExcel"
}
