import {Axios, Base} from "@/api/Base"

const api = "space"

export class SpaceGetTree extends Base {
    path = api + "/getTree"
}

export class SpaceInfoByUserId extends Base {
    path = api + "/getInfoByUserId"
}

export class SpaceGetSpaceIdsByUserId extends Base {
    path = api + "/getSpaceIdsByUserId"
}